import axios from 'axios'
const baseURL = `${process.env.VUE_APP_CMS_API_URL}/cms/admins`

export default {
  async fetchEnumTypes() {
    return await axios.get(`${baseURL}/enum-types`)
  },
  async fetchCmsSites() {
    return await axios.get(`${baseURL}/sites`)
  },
  async fetchCmsSiteById(siteId) {
    return await axios.get(`${baseURL}/sites/${siteId}`)
  },
  async createCmsSite(data) {
    return await axios.post(`${baseURL}/sites`, data)
  },
  async updateCmsSite(siteId, data) {
    return await axios.put(`${baseURL}/sites/${siteId}`, data)
  },
  async fetchCmsSiteFiles(siteId) {
    return await axios.get(`${baseURL}/sites/${siteId}/files`)
  },
  async uploadCmsFiles(siteId, formData) {
    return await axios.post(`${baseURL}/sites/${siteId}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  async removeFile(siteId, fileId) {
    return await axios.delete(
      `${baseURL}/sites/${siteId}/files?fileId=${fileId}`
    )
  },
  async flushSiteCache() {
    return await axios.delete(`${baseURL}/sites/flush`)
  },

  async createBanners(data) {
    return await axios.post(`${baseURL}/sites/banners`, data)
  },
  async removeBanner(siteId, bannerId) {
    return await axios.delete(
      `${baseURL}/sites/banners?cmsSiteId=${siteId}&bannerId=${bannerId}`
    )
  },
  async createPromotions(data) {
    return await axios.post(`${baseURL}/sites/promotions`, data)
  },
  async removePromotion(siteId, promotionId) {
    return await axios.delete(
      `${baseURL}/sites/promotions?cmsSiteId=${siteId}&promotionId=${promotionId}`
    )
  },
  async createReferrals(data) {
    return await axios.post(`${baseURL}/sites/referrals`, data)
  },
  async removeReferral(siteId, referralId) {
    return await axios.delete(
      `${baseURL}/sites/referrals?cmsSiteId=${siteId}&referralId=${referralId}`
    )
  },
  async createFloatingMenus(data) {
    return await axios.post(`${baseURL}/sites/floating-menus`, data)
  },
  async removeFloatingMenu(siteId, floatingMenuId) {
    return await axios.delete(
      `${baseURL}/sites/floating-menus?cmsSiteId=${siteId}&floatingMenuId=${floatingMenuId}`
    )
  },
  async createFloatingAds(data) {
    return await axios.post(`${baseURL}/sites/floating-ads`, data)
  },
  async removeFloatingAds(siteId, floatingAdsId) {
    return await axios.delete(
      `${baseURL}/sites/floating-ads?cmsSiteId=${siteId}&floatingAdsId=${floatingAdsId}`
    )
  },
  async createLayout(data) {
    return await axios.post(`${baseURL}/sites/layouts`, data)
  },
  async updateLayout(siteId, layoutId, data) {
    return await axios.put(
      `${baseURL}/sites/${siteId}/layouts/${layoutId}`,
      data
    )
  },
  async removeLayout(siteId, layoutId) {
    return await axios.delete(
      `${baseURL}/sites/layouts?cmsSiteId=${siteId}&cmsLayoutId=${layoutId}`
    )
  },
  async createLayoutRow(data) {
    return await axios.post(`${baseURL}/sites/layouts/rows`, data)
  },
  async removeLayoutRow(siteId, layoutId, rowId) {
    return await axios.delete(
      `${baseURL}/sites/layouts/rows?cmsSiteId=${siteId}&cmsLayoutId=${layoutId}&cmsRowId=${rowId}`
    )
  },
  async enableDevelopment(siteId) {
    return await axios.post(`${baseURL}/sites/${siteId}/enable-development`)
  },
  async goProd(siteId) {
    return await axios.post(`${baseURL}/sites/${siteId}/go-prod`)
  },
  async deleteDevSite(siteId) {
    return await axios.delete(`${baseURL}/sites?cmsSiteId=${siteId}`)
  },
  async fetchArticles(siteId) {
    return await axios.get(`${baseURL}/sites/articles?cmsSiteId=${siteId}`)
  },
  async createArticle(data) {
    return await axios.post(`${baseURL}/sites/articles`, data)
  },
  async updateArticle(siteId, articleId, data) {
    return await axios.put(
      `${baseURL}/sites/${siteId}/articles/${articleId}`,
      data
    )
  },
  async removeArticle(siteId, articleId) {
    return await axios.delete(
      `${baseURL}/sites/${siteId}/articles?articleId=${articleId}`
    )
  },
}
