export default [
  {
    key: 'account',
    icon: '',
    path: '/account',
    name: 'VIEW_PROFILE',
    route: 'VIEW_PROFILE',
    component: () => import('@/views/Account.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: false,
    },
  },
]
