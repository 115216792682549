import WhitelistService from '../../services/whitelist'

const mutations = {
  FETCH_WHITELISTS_REQUEST(state) {
    state.isFetchingWhitelists = true
  },
  FETCH_WHITELISTS_SUCCESS(state, payload) {
    state.isFetchingWhitelists = false
    state.whitelists = payload
  },
  FETCH_WHITELISTS_FAILURE(state) {
    state.isFetchingWhitelists = false
  },
  ADD_WHITELIST_REQUEST(state) {
    state.isCreatingWhitelist = true
  },
  ADD_WHITELIST_SUCCESS(state) {
    state.isCreatingWhitelist = false
  },
  ADD_WHITELIST_FAILURE(state) {
    state.isCreatingWhitelist = false
  },
  DELETE_WHITELIST_REQUEST(state) {
    state.isDeletingWhitelist = true
  },
  DELETE_WHITELIST_SUCCESS(state) {
    state.isDeletingWhitelist = false
  },
  DELETE_WHITELIST_FAILURE(state) {
    state.isDeletingWhitelist = false
  },
}

const actions = {
  async fetchWhitelists(
    { commit, dispatch },
    { limit = '', page = '', search = '', masterId = '', agentId = '' }
  ) {
    try {
      commit('FETCH_WHITELISTS_REQUEST')
      const response = await WhitelistService.fetchWhitelists(
        limit,
        page,
        search,
        masterId,
        agentId
      )
      commit('FETCH_WHITELISTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WHITELISTS_FAILURE', e.message)
    }
  },
  async createWhitelist({ commit, dispatch }, data) {
    try {
      commit('ADD_WHITELIST_REQUEST')
      await WhitelistService.createWhitelist(data)
      commit('ADD_WHITELIST_SUCCESS')
      dispatch('notifySuccess', 'added')
      dispatch('fetchWhitelists', {
        limit: '',
        page: '',
        search: '',
        masterId: '',
        agentId: '',
      })
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_WHITELIST_FAILURE', e.message)
    }
  },
  async deleteWhitelist({ commit, dispatch }, id) {
    try {
      commit('DELETE_WHITELIST_REQUEST')
      await WhitelistService.deleteWhitelist(id)
      commit('DELETE_WHITELIST_SUCCESS')
      dispatch('fetchWhitelists', {
        limit: '',
        page: '',
        search: '',
        masterId: '',
        agentId: '',
      })
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_WHITELIST_FAILURE', e.message)
    }
  },
}

const getters = {}

const state = {
  isFetchingWhitelists: false,
  isCreatingWhitelist: false,
  isDeletingWhitelist: false,
  whitelists: [],
}

export default {
  mutations,
  actions,
  getters,
  state,
}
