import router from '../../router/index'
import AssistantService from '../../services/assistant'

const mutations = {
  FETCH_ASSISTANTS_REQUEST(state) {
    state.isFetchingAssistants = true
    state.isFetchingAssistantsError = false
    state.isFetchingAssistantsSuccess = false
  },
  FETCH_ASSISTANTS_SUCCESS(state, payload) {
    state.isFetchingAssistants = false
    state.isFetchingAssistantsSuccess = true
    state.assistants = payload
  },
  FETCH_ASSISTANTS_FAILURE(state, payload) {
    state.isFetchingAssistants = false
    state.isFetchingAssistantsError = payload
  },
  FETCH_ASSISTANT_REQUEST(state) {
    state.isFetchingAssistant = true
    state.isFetchingAssistantError = false
    state.isFetchingAssistantSuccess = false
  },
  FETCH_ASSISTANT_SUCCESS(state, payload) {
    state.isFetchingAssistant = false
    state.isFetchingAssistantSuccess = true
    state.assistantDetail = payload
  },
  FETCH_ASSISTANT_FAILURE(state, payload) {
    state.isFetchingAssistant = false
    state.isFetchingAssistantError = payload
  },
  CREATE_ASSISTANT_REQUEST(state) {
    state.isCreatingAssistant = true
    state.isCreatingAssistantError = false
    state.isCreatingAssistantSuccess = false
  },
  CREATE_ASSISTANT_SUCCESS(state) {
    state.isCreatingAssistant = false
    state.isCreatingAssistantSuccess = true
  },
  CREATE_ASSISTANT_FAILURE(state, payload) {
    state.isCreatingAssistant = false
    state.isCreatingAssistantError = payload
  },
  UPDATE_ASSISTANT_REQUEST(state) {
    state.isUpdatingAssistant = true
    state.isUpdatingAssistantError = false
    state.isUpdatingAssistantSuccess = false
  },
  UPDATE_ASSISTANT_SUCCESS(state) {
    state.isUpdatingAssistant = false
    state.isUpdatingAssistantSuccess = true
  },
  UPDATE_ASSISTANT_FAILURE(state, payload) {
    state.isUpdatingAssistant = false
    state.isUpdatingAssistantError = payload
  },
  RESET_PASSWORD_ASSISTANT_REQUEST(state) {
    state.isResettingPasswordAssistant = true
    state.isResettingPasswordAssistantError = false
    state.isResettingPasswordAssistantSuccess = false
  },
  RESET_PASSWORD_ASSISTANT_SUCCESS(state) {
    state.isResettingPasswordAssistant = false
    state.isResettingPasswordAssistantSuccess = true
  },
  RESET_PASSWORD_ASSISTANT_FAILURE(state, payload) {
    state.isResettingPasswordAssistant = false
    state.isResettingPasswordAssistantError = payload
  },
  SET_ASSISTANT_PERMISSIONS_REQUEST(state) {
    state.isSettingAssistantPermissions = true
    state.isSettingAssistantPermissionsError = false
    state.isSettingAssistantPermissionsSuccess = false
  },
  SET_ASSISTANT_PERMISSIONS_SUCCESS(state) {
    state.isSettingAssistantPermissions = false
    state.isSettingAssistantPermissionsSuccess = true
  },
  SET_ASSISTANT_PERMISSIONS_FAILURE(state, payload) {
    state.isSettingAssistantPermissions = false
    state.isSettingAssistantPermissionsError = payload
  },
  DELETE_ASSISTANT_REQUEST(state) {
    state.isDeletingAssistant = true
  },
  DELETE_ASSISTANT_SUCCESS(state) {
    state.isDeletingAssistant = false
  },
  DELETE_ASSISTANT_FAILURE(state) {
    state.isDeletingAssistant = false
  },
}
const actions = {
  async fetchAssistants({ commit, dispatch }, { limit = '', page = '', search = '', agentId = '', masterId = '' }) {
    try {
      commit('FETCH_ASSISTANTS_REQUEST')
      const response = await AssistantService.getAssistants(limit, page, search, agentId, masterId)
      commit('FETCH_ASSISTANTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ASSISTANTS_FAILURE', e.message)
    }
  },
  async fetchAssistant({ commit, dispatch }, id) {
    try {
      commit('FETCH_ASSISTANT_REQUEST')
      const response = await AssistantService.fetchAssistantById(id)
      commit('FETCH_ASSISTANT_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ASSISTANT_FAILURE', e.message)
    }
  },
  async createAssistant({ commit, dispatch }, args) {
    try {
      commit('CREATE_ASSISTANT_REQUEST')
      await AssistantService.createAssistant(args)
      commit('CREATE_ASSISTANT_SUCCESS')
      dispatch('notifySuccess', 'created')
      router.push('/assistants/list')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_ASSISTANT_FAILURE', e.message)
    }
  },
  async updateAssistant({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_ASSISTANT_REQUEST')

      await AssistantService.updateAssistant(id, data)
      commit('UPDATE_ASSISTANT_SUCCESS')
      dispatch('fetchAssistant', id)
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_ASSISTANT_FAILURE', e.message)
    }
  },
  async resetPasswordAssistant({ commit, dispatch }, { assistantId, data }) {
    try {
      commit('RESET_PASSWORD_ASSISTANT_REQUEST')
      await AssistantService.resetPassword(assistantId, data)
      commit('RESET_PASSWORD_ASSISTANT_SUCCESS')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('RESET_PASSWORD_ASSISTANT_FAILURE', e.message)
    }
  },
  async setAssistantPermissions({ commit, dispatch }, { assistantId, form }) {
    try {
      commit('SET_ASSISTANT_PERMISSIONS_REQUEST')
      await AssistantService.setExcludePermissions(assistantId, form)
      commit('SET_ASSISTANT_PERMISSIONS_SUCCESS')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('SET_ASSISTANT_PERMISSIONS_FAILURE', e.message)
    }
  },
  async deleteAssistant({ commit, dispatch }, assistantId) {
    try {
      commit('DELETE_ASSISTANT_REQUEST')
      await AssistantService.deleteAssistant(assistantId)
      commit('DELETE_ASSISTANT_SUCCESS')
      dispatch('fetchAssistants', { limit: 50, offset: 0 })
      dispatch('notifySuccess', 'deleted')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_ASSISTANT_FAILURE', e.message)
    }
  },
}
const getters = {
  assistants: (state) => state.assistants,
  assistantDetail: (state) => state.assistantDetail,
}
const state = {
  assistants: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingAssistants: false,
  isFetchingAssistantsError: '',
  isFetchingAssistantsSuccess: false,

  assistantDetail: { permissions: []},
  isFetchingAssistant: false,
  isFetchingAssistantError: '',
  isFetchingAssistantSuccess: false,

  isCreatingAssistant: false,
  isCreatingAssistantError: '',
  isCreatingAssistantSuccess: false,
  isUpdatingAssistant: false,
  isUpdatingAssistantError: '',
  isUpdatingAssistantSuccess: false,
  iDeletingAssistant: false,

  isBanningAssistant: false,
  isBanningAssistantError: '',
  isBanningAssistantSuccess: false,
  isDeletingAssistant: false,
  isDeletingAssistantError: '',
  isDeletingAssistantSuccess: false,

  isResettingPasswordAssistant: false,
  isResettingPasswordAssistantError: '',
  isResettingPasswordAssistantSuccess: false,

  isSettingAssistantPermissions: false,
  isSettingAssistantPermissionsError: '',
  isSettingAssistantPermissionsSuccess: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
