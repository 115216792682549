export default [
  {
    key: 'agent:view.user',
    icon: 'UsersIcon',
    path: 'list',
    name: 'VIEW_PLAYERS',
    route: 'VIEW_PLAYERS',
    component: () => import('@/views/players/PlayerList'),
    meta: { 
      authRequired: true,
      isAppMenu: true 
    },
  },
  {
    path: '/players',
    name: 'PLAYERS',
    meta: { 
      authRequired: true,
      isAppMenu: false  
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'detail/:id',
        name: 'VIEW_PLAYER_DETAIL',
        component: () => import('@/views/players/PlayerDetail'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/players/list', name: 'VIEW_PLAYERS' },
            { href: '/players/detail/:id', name: 'VIEW_PLAYER_DETAIL' },
          ],
        },
      },
      {
        path: 'create',
        name: 'CREATE_PLAYER',
        component: () => import('@/views/players/PlayerCreate'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/players/list', name: 'VIEW_PLAYERS' },
            { href: '/players/create', name: 'CREATE_PLAYER' },
          ],
        },
      },
    ],
  },
]