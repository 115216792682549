<template>
  <div v-if="isOwner">
    <validation-provider
      v-slot="{ errors }"
      name="มาสเตอร์"
      :rules="required?'required':''"
    >
      <b-form-group
        :label-cols-sm="hideLabel?null:'12'"
        :label-cols-lg="hideLabel?null:labelCols || '3'"
        :label="hideLabel?null:`มาสเตอร์ ${required? '*':''}`"
      >
        <b-select
          v-model="masterId"
          :state="errors[0] ? false : null"
          :disabled="readonly"
        >
          <b-form-select-option value="">
            {{ hasSelectAll?'เลือกทุกมาสเตอร์': 'เลือกมาสเตอร์' }}
          </b-form-select-option>

          <b-form-select-option
            v-for="(user, userI) in masterList"
            :key="userI"
            :value="user.id"
          >
            {{ user.name }}
          </b-form-select-option>
        </b-select>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MasterSelectInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      masterId: '',
    }
  },
  computed: {
    ...mapGetters(['isOwner','userInfo']),
    masterList() {
      return this.userInfo.masters
    },
  },
  watch: {
    masterId(val) {
      this.$emit('update', val)
    },
    value(val) {
      if (val && !this.masterId) {
        this.masterId = val
      }
    },
  },
  created() {
    if (this.value) {
      this.masterId = this.value
    }
  },
  methods: {
    ...mapActions(['fetchMasters']),
  },
}
</script>
