import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/agent`

export default {
  fetchAgents(limit, page, search, masterId) {
    try {
      return axios.get(
        `${baseURL}?limit=${limit}&page=${page}&search=${search}&masterId=${masterId}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchAgentById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  createAgent(data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(`${baseURL}`, formData, options)
    } catch (e) {
      throw e
    }
  },
  updateAgent(id, data) {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if(Array.isArray(value)){
          value.forEach((prop, i) => {
            for (const [arrKey, arrValue] of Object.entries(prop)) {
              formData.append(`${key}[${i}][${arrKey}]`, arrValue);
            }
          })
        } else {
          formData.append(`${key}`, value);
        }
      }
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.put(`${baseURL}/${id}`, formData, options)
    } catch (e) {
      throw e
    }
  },
  deleteAgent(id) {
    try {
      return axios.delete(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  loginAsAgent(id) {
    try {
      return axios.post(`${baseURL}/${id}/login-as`)
    } catch (e) {
      throw e
    }
  },
  depositAgentCredit(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/deposit`, data)
    } catch (e) {
      throw e
    }
  },
  withdrawAgentCredit(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/withdraw`, data)
    } catch (e) {
      throw e
    }
  },
}
