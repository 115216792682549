export default [
  {
    key: 'agent:view.summary',
    icon: 'ActivityIcon',
    path: '/summary',
    name: 'VIEW_SUMMARY',
    route: 'VIEW_SUMMARY',
    component: () => import('@/views/Summary.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: true,
    },
  },
]
