export default [
  {
    key: 'settings',
    icon: 'KeyIcon',
    path: '/settings',
    header: 'SETTINGS',
    name: 'SETTINGS',
    meta: { 
      authRequired: true,
      isAppMenu: true,
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        key: 'master:view.bank-account',
        icon: 'BookIcon',
        path: '/bank-accounts',
        name: 'VIEW_BANK_ACCOUNTS',
        component: () => import('@/views/BankList.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true,
        },
      },
      {
        key: 'agent:view.user-agent',
        icon: 'KeyIcon',
        path: '/assistants/list',
        name: 'VIEW_ASSISTANTS',
        component: () => import('@/views/assistants/AssistantList.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true,
        },
      },
      {
        key: 'agent:view.promotion',
        icon: 'TagIcon',
        path: '/promotions/list',
        name: 'VIEW_PROMOTIONS',
        component: () => import('@/views/promotions/PromotionList.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true,
        },
      },
      {
        key: 'agent:view.login-log',
        icon: 'LogInIcon',
        path: '/user-access',
        name: 'VIEW_USER_ACCESS',
        component: () => import('@/views/settings/AccessList.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
      {
        key: 'agent:view.role',
        icon: 'UserCheckIcon',
        path: '/roles',
        name: 'VIEW_ROLES',
        component: () => import('@/views/settings/Roles.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
      {
        key: 'agent:view.line-notification',
        icon: 'BellIcon',
        path: '/line-notifications',
        name: 'VIEW_LINE_NOTIFICATIONS',
        component: () => import('@/views/settings/LineNotifications.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
      {
        key: 'agent:view.blacklist',
        icon: 'XOctagonIcon',
        path: '/blacklists',
        name: 'VIEW_BLACKLISTS',
        component: () => import('@/views/settings/Blacklists.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
      {
        key: 'agent:view.whitelist',
        icon: 'CheckCircleIcon',
        path: '/whitelists',
        name: 'VIEW_WHITELISTS',
        component: () => import('@/views/settings/Whitelists.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
      {
        key: 'agent:manage.cms',
        icon: 'LayersIcon',
        path: '/cms/list',
        name: 'VIEW_CMS',
        component: () => import('@/views/cms/Cms.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
      {
        key: 'agent:create.announce',
        icon: 'Volume2Icon',
        path: '/announce',
        name: 'CREATE_ANNOUNCE',
        component: () => import('@/views/settings/Announcement.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
    ],
  },
  {
    path: '/assistants',
    name: 'ASSISTANTS',
    meta: { 
      authRequired: true,
      isAppMenu: false, },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'detail/:id',
        name: 'VIEW_ASSISTANT_DETAIL',
        component: () => import('@/views/assistants/AssistantDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/assistants/list', name: 'VIEW_ASSISTANTS' },
            { href: '/assistants/detail/:id', name: 'VIEW_ASSISTANT_DETAIL' },
          ],
        },
      },
      {
        path: 'create',
        name: 'CREATE_ASSISTANT',
        component: () => import('@/views/assistants/AssistantDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/assistants/list', name: 'VIEW_ASSISTANTS' },
            { href: '/assistants/create', name: 'CREATE_ASSISTANT' },
          ],
        },
      },
    ],
  },
  {
    path: '/promotions',
    name: 'PROMOTIONS',
    meta: { 
      authRequired: true,
      isAppMenu: false
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'detail/:id',
        name: 'VIEW_PROMOTION_DETAIL',
        component: () => import('@/views/promotions/PromotionDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/promotions/list', name: 'VIEW_PROMOTIONS' },
            { href: '/promotions/detail/:id', name: 'VIEW_PROMOTION_DETAIL' },
          ],
        },
      },
      {
        path: 'create',
        name: 'CREATE_PROMOTION',
        component: () => import('@/views/promotions/PromotionDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/promotions/list', name: 'VIEW_PROMOTIONS' },
            { href: '/promotions/create', name: 'CREATE_PROMOTION' },
          ],
        },
      },
    ],
  },
  {
    path: '/permissions',
    name: 'VIEW_PERMISSIONS',
    component: () => import('@/views/settings/Permissions.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: false
    },
  },
  {
    path: '/cms',
    name: 'CMS',
    meta: { 
      authRequired: true,
      isAppMenu: false
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'detail/:id',
        name: '',
        component: () => import('@/views/cms/CmsDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/cms', name: 'VIEW_CMS' },
            {
              href: '/cms/detail/:id',
              name: 'VIEW_CMS',
            },
          ],
        },
      }
    ]
  },
  {
    path: '/bank-accounts',
    name: 'BANK_ACCOUNTS',
    meta: { 
      authRequired: true,
      isAppMenu: false,
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'detail/:id',
        name: 'VIEW_BANK_ACCOUNT_DETAIL',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/bank-accounts/list', name: 'VIEW_BANK_ACCOUNTS' },
            {
              href: '/bank-accounts/detail/:id',
              name: 'VIEW_BANK_ACCOUNT_DETAIL',
            },
          ],
        },
        component: () => import('@/views/BankDetail.vue'),
      },
      {
        path: 'create',
        name: 'CREATE_BANK_ACCOUNT',
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/bank-accounts/list', name: 'VIEW_BANK_ACCOUNTS' },
            { href: '/bank-accounts/create', name: 'CREATE_BANK_ACCOUNT' },
          ],
        },
        component: () => import('@/views/BankDetail.vue'),
      },
    ],
  },
]