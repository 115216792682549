import axios from 'axios'
import { v4 as uuid } from 'uuid'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/user`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export default {
  createPlayer(data) {
    try {
      return axios.post(baseURL, data)
    } catch (e) {
      throw e
    }
  },
  getPlayers(limit, page, search, masterId, agentId) {
    try {
      return axios.get(
        `${baseURL}?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchPlayerById(id) {
    try {
      return axios.get(`${baseURL}/${id}`)
    } catch (e) {
      throw e
    }
  },
  updatePlayer(id, data) {
    try {
      return axios.put(`${baseURL}/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  banPlayer(id) {
    try {
      return axios.put(`${userURL}/users/${id}/ban`)
    } catch (e) {
      throw e
    }
  },
  deletePlayer(id) {
    try {
      return axios.delete(`${userURL}/users/${id}`)
    } catch (e) {
      throw e
    }
  },
  depositPlayerCredit(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/deposit`, data)
    } catch (e) {
      throw e
    }
  },
  withdrawPlayerCredit(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/withdraw`, data)
    } catch (e) {
      throw e
    }
  },
  addFreeCreditPlayer(id) {
    try {
      return axios.post(`${baseURL}/${id}/free-credit`, { trackingId: uuid() })
    } catch (e) {
      throw e
    }
  },
  fetchPlayerAf(id) {
    try {
      return axios.get(`${baseURL}/${id}/affiliate`)
    } catch (e) {
      throw e
    }
  },
  fetchPlayerCashback(id) {
    try {
      return axios.get(`${baseURL}/${id}/cashback`)
    } catch (e) {
      throw e
    }
  },
  fetchPlayerPromotion(id) {
    try {
      return axios.get(`${baseURL}/${id}/promotion`)
    } catch (e) {
      throw e
    }
  },
}
