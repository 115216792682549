import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    appVersion: `${process.env.NODE_ENV} - ${process.env.VUE_APP_VERSION || ''}`,
    errorMessage: '',
    successMessage: '',

    isMakingSound: false,
  },
  getters: {
    appVersion: (state) => state.appVersion,
    isMakingSound: (state) => state.isMakingSound,
    errorMessage: (state) => state.errorMessage,
    successMessage: (state) => state.successMessage,
    currentBreakPoint: (state) => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    CLEAR_NOTIFY_ERROR(state) {
      state.errorMessage = '';
      state.successMessage = '';
    },
    NOTIFY_ERROR(state, payload) {
      state.errorMessage = payload
    },
    NOTIFY_SUCCESS(state, payload) {
      state.successMessage = payload
    },
    MAKE_SOUND(state) {
      state.isMakingSound = true
    },
    MUTE_SOUND(state) {
      state.isMakingSound = false
    },
  },
  actions: {
    notifyError({ commit }, error) {
      const { code, message } = error
      let msg = message ? `${code} ${message}` : error
      
      commit('NOTIFY_ERROR', msg)
    },
    notifySuccess({ commit }, response) {
      commit('NOTIFY_SUCCESS', `Your request has been ${response || 'sent'} successfully.`)
    },
    makeSound({ commit }) {
      commit('MAKE_SOUND')
    },
    muteSound({ commit }) {
      commit('MUTE_SOUND')
    },
  },
}
