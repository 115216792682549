import GameService from '../../services/game'
// import i18n from '../../locales/index'
import GameProviderService from '../../services/game-provider'

const mutations = {
  FETCH_GAMES_REQUEST(state) {
    state.isFetchingGames = true
    state.isFetchingGamesError = false
    state.isFetchingGamesSuccess = false
  },
  FETCH_GAMES_SUCCESS(state, payload) {
    state.isFetchingGames = false
    state.isFetchingGamesSuccess = true
    state.miniGames = payload
  },
  FETCH_GAMES_FAILURE(state) {
    state.isFetchingGames = false
    state.isFetchingGamesError = true
  },
  FETCH_GAME_REQUEST(state) {
    state.isFetchingGame = true
    state.isFetchingGameError = false
    state.isFetchingGameSuccess = false
  },
  FETCH_GAME_SUCCESS(state, payload) {
    state.isFetchingGame = false
    state.isFetchingGameSuccess = true
    state.gameDetail = payload
  },
  FETCH_GAME_FAILURE(state) {
    state.isFetchingGame = false
    state.isFetchingGameError = true
  },
  FETCH_GAME_SCORE_REQUEST(state) {
    state.isFetchingGameScore = true
    state.isFetchingGameScoreError = false
    state.isFetchingGameScoreSuccess = false
  },
  FETCH_GAME_SCORE_SUCCESS(state, { game, data }) {
    state.isFetchingGameScore = false
    state.isFetchingGameScoreSuccess = true
    state.gameScore = {
      game,
      ...data,
    }
  },
  FETCH_GAME_SCORE_FAILURE(state) {
    state.isFetchingGameScore = false
    state.isFetchingGameScoreError = true
  },
  FETCH_GAME_REPORT_REQUEST(state) {
    state.isFetchingGameReport = true
    state.isFetchingGameReportError = false
    state.isFetchingGameReportSuccess = false
  },
  FETCH_GAME_REPORT_SUCCESS(state, payload) {
    state.isFetchingGameReport = false
    state.isFetchingGameReportSuccess = true
    state.gameReport = payload
  },
  FETCH_GAME_REPORT_FAILURE(state) {
    state.isFetchingGameReport = false
    state.isFetchingGameReportError = true
  },
  CREATE_GAME_REQUEST(state) {
    state.isCreatingGame = true
    state.isCreatingGameError = false
    state.isCreatingGameSuccess = false
  },
  CREATE_GAME_SUCCESS(state) {
    state.isCreatingGame = false
    state.isCreatingGameSuccess = true
  },
  CREATE_GAME_FAILURE(state) {
    state.isCreatingGame = false
    state.isCreatingGameError = true
  },
  UPDATE_GAME_REQUEST(state) {
    state.isUpdatingGame = true
    state.isUpdatingGameError = false
    state.isUpdatingGameSuccess = false
  },
  UPDATE_GAME_SUCCESS(state) {
    state.isUpdatingGame = false
    state.isUpdatingGameSuccess = true
  },
  UPDATE_GAME_FAILURE(state) {
    state.isUpdatingGame = false
    state.isUpdatingGameError = true
  },
  UPDATE_GAME_STATUS_REQUEST(state) {
    state.isUpdatingGameStatus = true
    state.isUpdatingGameStatusError = false
    state.isUpdatingGameStatusSuccess = false
  },
  UPDATE_GAME_STATUS_SUCCESS(state) {
    state.isUpdatingGameStatus = false
    state.isUpdatingGameStatusSuccess = true
  },
  UPDATE_GAME_STATUS_FAILURE(state) {
    state.isUpdatingGameStatus = false
    state.isUpdatingGameStatusError = true
  },
  FETCH_GAME_AGENT_COMMISSION_REQUEST(state) {
    state.isFetchingGameAgentCommission = true
    state.isFetchingGameAgentCommissionError = false
    state.isFetchingGameAgentCommissionSuccess = false
  },
  FETCH_GAME_AGENT_COMMISSION_SUCCESS(state, payload) {
    state.isFetchingGameAgentCommission = false
    state.isFetchingGameAgentCommissionSuccess = true
    state.agentCommissions = payload
  },
  FETCH_GAME_AGENT_COMMISSION_FAILURE(state) {
    state.isFetchingGameAgentCommission = false
    state.isFetchingGameAgentCommissionError = true
  },
  FETCH_BOT_CREDITS_REQUEST(state) {
    state.isFetchingBotCredits = true
    state.isFetchingBotCreditsError = false
    state.isFetchingBotCreditsSuccess = false
  },
  FETCH_BOT_CREDITS_SUCCESS(state, payload) {
    state.isFetchingBotCredits = false
    state.isFetchingBotCreditsSuccess = true
    state.botCredits = payload
  },
  FETCH_BOT_CREDITS_FAILURE(state) {
    state.isFetchingBotCredits = false
    state.isFetchingBotCreditsError = true
  },
  FETCH_BOT_CREDITS_TODAY_REQUEST(state) {
    state.isFetchingBotCreditsToday = true
    state.isFetchingBotCreditsTodayError = false
    state.isFetchingBotCreditsTodaySuccess = false
  },
  FETCH_BOT_CREDITS_TODAY_SUCCESS(state, payload) {
    state.isFetchingBotCreditsToday = false
    state.isFetchingBotCreditsTodaySuccess = true
    state.botCreditsToday = payload
  },
  FETCH_BOT_CREDITS_TODAY_FAILURE(state) {
    state.isFetchingBotCreditsToday = false
    state.isFetchingBotCreditsTodayError = true
  },
  FETCH_PROVIDER_GAMES_REQUEST(state) {
    state.isFetchingProviderGames = true
  },
  FETCH_PROVIDER_GAMES_SUCCESS(state, payload) {
    state.isFetchingProviderGamesError = false
    state.isFetchingProviderGamesSuccess = true
    state.providerGames = payload
  },
  FETCH_PROVIDER_GAMES_FAILURE(state) {
    state.isFetchingProviderGamesSuccess = true
    state.isFetchingProviderGamesError = true
  },
  FETCH_GAME_TYPES_REQUEST(state) {
    state.isFetchingGameTypes = true
    state.isFetchingGameTypesError = false
    state.isFetchingGameTypesSuccess = false
  },
  FETCH_GAME_TYPES_SUCCESS(state, payload) {
    state.isFetchingGameTypes = false
    state.isFetchingGameTypesSuccess = true
    state.gameTypes = payload
  },
  FETCH_GAME_TYPES_FAILURE(state) {
    state.isFetchingGameTypes = false
    state.isFetchingGameTypesError = true
  },
  FETCH_GAME_SUMMARY_REQUEST(state) {
    state.isFetchingGameSummary = true
  },
  FETCH_GAME_SUMMARY_SUCCESS(state, payload) {
    state.isFetchingGameSummary = false
    state.gameSummary = payload
  },
  FETCH_GAME_SUMMARY_FAILURE(state) {
    state.isFetchingGameSummary = false
  },
  FETCH_TRANSACTIONS_REQUEST(state) {
    state.isFetchingTransactions = true
    state.isFetchingTransactionsError = ''
  },
  FETCH_TRANSACTIONS_SUCCESS(state, payload) {
    state.isFetchingTransactions = false
    state.gameTransactions = payload
  },
  FETCH_TRANSACTIONS_FAILURE(state, payload) {
    state.isFetchingTransactions = false
    state.isFetchingTransactionsError = payload
  },
}
const actions = {
  async fetchGameTypes({ commit }) {
    try {
      commit('FETCH_GAME_TYPES_REQUEST')
      await GameService.fetchGameTypes()
      commit('FETCH_GAME_TYPES_SUCCESS')
    } catch (e) {
      commit('FETCH_GAME_TYPES_FAILURE')
    }
  },
  async fetchProviderGames({ commit }) {
    try {
      commit('FETCH_PROVIDER_GAMES_REQUEST')
      const response = await GameProviderService.fetchGames()
      commit('FETCH_PROVIDER_GAMES_SUCCESS', response)
    } catch (e) {
      commit('FETCH_PROVIDER_GAMES_FAILURE')
    }
  },
  async fetchGames({ commit }) {
    try {
      commit('FETCH_GAMES_REQUEST')
      const response = await GameService.fetchGames()
      commit('FETCH_GAMES_SUCCESS', response)
    } catch (e) {
      commit('FETCH_GAMES_FAILURE', e.message)
    }
  },
  async fetchGame({ commit, dispatch }, gameId) {
    try {
      commit('FETCH_GAME_REQUEST')
      const response = await GameService.fetchGameById(gameId)
      commit('FETCH_GAME_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_GAME_FAILURE', e.message)
    }
  },
  async fetchGameScore(
    { commit, dispatch },
    { slug, date, limit, page, agentUsername, roomId }
  ) {
    try {
      commit('FETCH_GAME_SCORE_REQUEST')
      if (agentUsername) {
        const response = await GameService.fetchGameScoreBySlugWithAgent(
          slug,
          date,
          limit,
          page,
          agentUsername
        )
        commit('FETCH_GAME_SCORE_SUCCESS', { game: slug, data: response })
      } else if (roomId) {
        const response = await GameService.fetchGameScoreBySlugWithRoomId(
          slug,
          date,
          limit,
          page,
          roomId
        )
        commit('FETCH_GAME_SCORE_SUCCESS', { game: slug, data: response })
      } else {
        const response = await GameService.fetchGameScoreBySlug(
          slug,
          date,
          limit,
          page
        )
        commit('FETCH_GAME_SCORE_SUCCESS', { game: slug, data: response })
      }
    } catch (e) {
      commit('FETCH_GAME_SCORE_FAILURE', e.message)
      dispatch('notifyError', e)
    }
  },
  async fetchGameReport({ commit, dispatch }) {
    try {
      commit('FETCH_GAME_REPORT_REQUEST')
      const response = await GameService.fetchGameReports()
      commit('FETCH_GAME_REPORT_SUCCESS', response)
    } catch (e) {
      commit('FETCH_GAME_REPORT_FAILURE', e.message)
      dispatch('notifyError', e)
    }
  },
  async fetchGameSummary(
    { commit, dispatch },
    { startDate, endDate, agentName }
  ) {
    try {
      commit('FETCH_GAME_SUMMARY_REQUEST')
      const response = await GameProviderService.fetchGameSummary(
        startDate,
        endDate,
        agentName
      )
      commit('FETCH_GAME_SUMMARY_SUCCESS', response)
    } catch (e) {
      commit('FETCH_GAME_SUMMARY_FAILURE', e.message)
      dispatch('notifyError', e)
    }
  },
  async createGame({ commit, dispatch }, args) {
    try {
      commit('CREATE_GAME_REQUEST')
      await GameService.createGame(args)
      commit('CREATE_GAME_SUCCESS')
      dispatch('notifySuccess', 'created')
      dispatch('fetchGames')
    } catch (e) {
      commit('CREATE_GAME_FAILURE', e.message)
    }
  },
  async updateGame({ commit, dispatch }, { id, formData }) {
    try {
      commit('UPDATE_GAME_REQUEST')
      await GameService.updateGame(id, formData)
      commit('UPDATE_GAME_SUCCESS')
      dispatch('notifySuccess', 'updated')
      dispatch('fetchGame', id)
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_GAME_FAILURE', e.message)
    }
  },
  async updateGameStatus({ commit, dispatch }, gameId) {
    try {
      commit('UPDATE_GAME_STATUS_REQUEST')
      await GameService.updateGameStatus(gameId)
      commit('UPDATE_GAME_STATUS_SUCCESS')
      dispatch('notifySuccess', 'updated')
      dispatch('fetchGame', gameId)
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_GAME_STATUS_FAILURE', e.message)
    }
  },
  async fetchGameAgentCommission({ commit }, { agentId, date }) {
    try {
      commit('FETCH_GAME_AGENT_COMMISSION_REQUEST')
      if (agentId) {
        const response = await GameService.fetchGameAgentCommissionByAgentId(
          agentId,
          date
        )
        commit('FETCH_GAME_AGENT_COMMISSION_SUCCESS', response)
      } else {
        const response = await GameService.fetchGameAgentCommission(date)
        commit('FETCH_GAME_AGENT_COMMISSION_SUCCESS', response)
      }
    } catch (e) {
      commit('FETCH_GAME_AGENT_COMMISSION_FAILURE', e.message)
    }
  },
  async fetchBotCredits({ commit }, { page, limit }) {
    try {
      commit('FETCH_BOT_CREDITS_REQUEST')
      const response = await GameService.fetchBotCredits(page, limit)
      commit('FETCH_BOT_CREDITS_SUCCESS', response)
    } catch (e) {
      commit('FETCH_BOT_CREDITS_FAILURE', e.message)
    }
  },
  async fetchBotCreditsToday({ commit }) {
    try {
      commit('FETCH_BOT_CREDITS_TODAY_REQUEST')
      const response = await GameService.fetchBotCreditsToday()
      commit('FETCH_BOT_CREDITS_TODAY_SUCCESS', response)
    } catch (e) {
      commit('FETCH_BOT_CREDITS_TODAY_FAILURE', e.message)
    }
  },
  async fetchTransaction({ commit }, { id, data }) {
    try {
      commit('FETCH_TRANSACTIONS_REQUEST')
      const response = await GameProviderService.fetchTransaction(id, data)
      commit('FETCH_TRANSACTIONS_SUCCESS', response)
    } catch (e) {
      commit('FETCH_TRANSACTIONS_FAILURE', e.message)
    }
  },
}
const getters = {
  gameTypes: (state) => state.gameTypes,
  miniGames: (state) => state.miniGames,
  gameDetail: (state) => state.gameDetail,
  gameScore: (state) => state.gameScore,
  gameReport: (state) => state.gameReport,
  agentCommissions: (state) => state.agentCommissions,
  botCredits: (state) => state.botCredits,
  botCreditsToday: (state) => state.botCreditsToday,
  providerGames: (state) => state.providerGames,
  lottoGames: (state) => state.lottoGames,
}
const state = {
  gameTypes: [
    {
      type: 'MINI_GAME',
      slug: 'mini_games',
      // name: i18n.t('games.mini_games'),
    },
    {
      type: 'CASINO',
      slug: 'provider-games',
      // name: i18n.t('games.provider_games'),
    },
    {
      type: 'LOTTO',
      slug: 'lotto-games',
      // name: i18n.t('games.lotto_games'),
    },
  ],
  isFetchingGameTypes: false,
  isFetchingGameTypesError: false,
  isFetchingGameTypesSuccess: false,

  isFetchingProviderGames: false,
  isFetchingProviderGamesError: false,
  isFetchingProviderGamesSuccess: false,

  isFetchingGames: false,
  isFetchingGamesError: false,
  isFetchingGamesSuccess: false,

  gameDetail: {},
  isFetchingGame: false,
  isFetchingGameError: false,
  isFetchingGameSuccess: false,

  gameScore: { game: '', allBet: 0, drinkWater: 0, totalCount: 0, items: [] },
  isFetchingGameScore: false,
  isFetchingGameScoreError: false,
  isFetchingGameScoreSuccess: false,

  gameReport: [],
  isFetchingGameReport: false,
  isFetchingGameReportError: false,
  isFetchingGameReportSuccess: false,

  gameSummary: {
    agentLists: [],
    total: {},
  },
  isFetchingGameSummary: false,

  isCreatingGame: false,
  isCreatingGameError: false,
  isCreatingGameSuccess: false,
  isUpdatingGame: false,
  isUpdatingGameError: false,
  isUpdatingGameSuccess: false,
  isUpdatingGameStatus: false,
  isUpdatingGameStatusError: false,
  isUpdatingGameStatusSuccess: false,

  isFetchingGameAgentCommission: false,
  isFetchingGameAgentCommissionError: false,
  isFetchingGameAgentCommissionSuccess: false,
  agentCommissions: [],

  isFetchingBotCredits: false,
  isFetchingBotCreditsError: false,
  isFetchingBotCreditsSuccess: false,
  botCredits: { items: [], limit: 0, page: 0, totalCount: 0 },
  isFetchingBotCreditsToday: false,
  isFetchingBotCreditsTodayError: false,
  isFetchingBotCreditsTodaySuccess: false,
  botCreditsToday: [],

  // default games
  // mini-games
  miniGames: [
    {
      id: 1,
      slug: 'head-tail',
      // gameName: i18n.t('games.head_tail'),
      gameType: 'mini-games',
      isActive: true,
    },
    {
      id: 2,
      slug: 'rock-paper-scissors',
      // gameName: i18n.t('games.rock_paper_scissors'),
      gameType: 'mini-games',
      isActive: true,
    },
    {
      id: 3,
      slug: 'hi-lo',
      // gameName: i18n.t('games.hi_lo'),
      gameType: 'mini-games',
      isActive: true,
    },
    {
      id: 4,
      slug: 'card-battle',
      // gameName: i18n.t('games.cards_battle'),
      gameType: 'mini-games',
      isActive: true,
    },
  ],
  // provider-games
  providerGames: [
    {
      id: 6,
      slug: 'general',
      // gameName: i18n.t('games.list.general'),
      gameType: 'provider',
      isActive: false,
    },
    // {
    //   id: 7,
    //   slug: 'slot',
    //   gameName: i18n.t('games.list.slot'),
    //   gameType: 'provider',
    //   isActive: false,
    // },
    // {
    //   id: 8,
    //   slug: 'fishing',
    //   gameName: i18n.t('games.list.fishing'),
    //   gameType: 'provider',
    //   isActive: false,
    // },
    // {
    //   id: 9,
    //   slug: 'casino',
    //   gameName: i18n.t('games.list.casino'),
    //   gameType: 'provider',
    //   isActive: false,
    // },
    // {
    //   id: 10,
    //   slug: 'sports',
    //   gameName: i18n.t('games.list.sports'),
    //   gameType: 'provider',
    //   isActive: false,
    // },
    // {
    //   id: 11,
    //   slug: 'simulated_sports',
    //   gameName: i18n.t('games.list.simulated_sports'),
    //   gameType: 'provider',
    //   isActive: false,
    // },
  ],
  // lotto-games
  lottoGames: [
    {
      id: 1,
      slug: 'government',
      // gameName: i18n.t('games.goverment'),
      gameType: 'lotto',
      isActive: true,
    },
    {
      id: 2,
      slug: 'yeekee',
      // gameName: i18n.t('games.yeekee'),
      gameType: 'lotto',
      isActive: false,
    },
  ],

  isFetchingTransactions: false,
  isFetchingTransactionsError: '',
  gameTransactions: {
    items: [],
  },
}
export default {
  state,
  mutations,
  actions,
  getters,
}
