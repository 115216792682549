<template>
  <div v-if="isShow || isOwner || isMaster">
    <validation-provider
      v-slot="{ errors }"
      :name="$t('agent.title')"
      :rules="required?'required':''"
    >
      <b-form-group
        :label-cols-sm="hideLabel?null:'12'"
        :label-cols-lg="hideLabel?null:labelCols || '3'"
        :label="hideLabel?null:`${$t('agent.title')} ${required? '*':''}`"
        label-for="agent"
      >
        <b-select
          id="agent"
          v-model="agentId"
          :state="errors[0] ? false : null"
          :disabled="readonly"
        >
          <b-form-select-option value="">
            {{ hasSelectAll?'เลือกทุกเอเยนต์': 'เลือกเอเยนต์' }}
          </b-form-select-option>

          <b-form-select-option
            v-for="(agent, agentI) in agentList"
            :key="agentI"
            :value="agent.id"
            :disabled="disabled"
          >
            {{ agent.name }}
          </b-form-select-option>
        </b-select>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AgentSelectInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelCols: {
      type: String,
      default: '3',
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    masterId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      agentId: '',
    }
  },
  computed: {
    ...mapGetters(['isOwner', 'isMaster', 'userInfo']),
    agentList() {
      const selectedMasterId = this.masterId? this.masterId: this.userInfo.agentId;
      const foundMyAgent = this.userInfo.masters.find((master) => master.id === selectedMasterId);
      return foundMyAgent? foundMyAgent.children: []
    },
  },
  watch: {
    agentId(val) {
      this.$emit('update', val)
    },
    value(val) {
      if (val && !this.agentId) {
        this.agentId = val
      }
    },
  },
  created() {
    if (this.value) {
      this.agentId = this.value
    }
  },
  methods: {
    ...mapActions(['fetchAgentsByMaster']),
    fetchData() {
      // this.fetchAgentsByMaster({
      //   limit: 20,
      //   page: 1,
      //   search: '',
      //   masterId: this.masterId,
      // })
    },
  }
}
</script>
