import router from '../../router/index'
import UserService from '../../services/user'

const mutations = {
  LOGIN_REQUEST(state) {
    state.isLoggingIn = true
  },
  LOGIN_SUCCESS(state) {
    state.isLoggingIn = false
    state.isLoggingInError = true
  },
  LOGIN_FAILURE(state) {
    state.isLoggingIn = false
  },
  VERIFY_LOGIN_REQUEST(state) {
    state.isVerifyingLogin = true
  },
  VERIFY_LOGIN_SUCCESS(state) {
    state.isVerifyingLogin = false
  },
  VERIFY_LOGIN_FAILURE(state) {
    state.isVerifyingLogin = false
  },
  VALIDATE_USER_SUCCESS(state) {
    state.loggedIn = true
  },
  VALIDATE_USER_FAILURE(state) {
    state.loggedIn = false
  },
}
const actions = {
  async getInitialProps({ commit, dispatch }) {
    const userToken = UserService.getAccessToken()
      if (userToken) {
      await dispatch('getMe')
      await dispatch('fetchPermissions')
      commit('VALIDATE_USER_SUCCESS')
    } else {
      commit('VALIDATE_USER_FAILURE')
    }
  },
  async login({ commit, dispatch }, { username, password }) {
    try {
      commit('LOGIN_REQUEST')
      const result = await UserService.login(username, password)
      const token = result?.data?.token ||result?.token

      // commit('LOGIN_SUCCESS', token)
      // history.pushState(
      //   {},
      //   null,
      //   `${this.$route.path}?verify=${}`
      // )
      if (token) {
        UserService.saveAccessToken(token)
        commit('LOGIN_SUCCESS', token)
        await dispatch('getInitialProps')
      }
    } catch (e) {
      console.log('e', e)
      dispatch('app/notifyError', e)
      commit('LOGIN_FAILURE')
    }
  },
  async verifyLogin({ commit, dispatch }, { ref, otp }) {
    try {
      commit('VERIFY_LOGIN_REQUEST')
      const result = await UserService.verifyLogin(ref, otp)
      const token = result?.data?.token ||result?.token
      if (token) {
        UserService.saveAccessToken(token)
        commit('VERIFY_LOGIN_SUCCESS', token)
        await dispatch('getInitialProps')
        const { path } = router.currentRoute
        if (path !== '/dashboard') {
          router.replace('/dashboard')
        }
      }
    } catch (e) {
      dispatch('notifyError', e)
      commit('VERIFY_LOGIN_FAILURE')
    }
  },
  async logout({ commit }) {
    localStorage.clear()
    commit('VALIDATE_USER_FAILURE')
    const { path } = router.currentRoute
    if (path !== '/login') {
      console.log('login')
      router.replace('/login')
    }
  },
}
const getters = {
  loggedIn: (state) => state.loggedIn,
}
const state = {
  loggedIn: false,
  isLoggingIn: false,
  isLoggingInError: false,
  isVerifyingLogin: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
