import RoleService from '../../services/roles'

const mutations = {
  FETCH_ROLES_REQUEST(state) {
    state.isFetchingRoles = true
  },
  FETCH_ROLES_SUCCESS(state, payload) {
    state.isFetchingRoles = false
    state.roles = payload
  },
  FETCH_ROLES_FAILURE(state) {
    state.isFetchingRoles = false
  },
  FETCH_ROLE_REQUEST(state) {
    state.isFetchingRole = true
  },
  FETCH_ROLE_SUCCESS(state, payload) {
    state.isFetchingRole = false
    state.roleDetail = payload
  },
  FETCH_ROLE_FAILURE(state) {
    state.isFetchingRole = false
  },
  ADD_ROLE_REQUEST(state) {
    state.isAddingRole = true
  },
  ADD_ROLE_SUCCESS(state) {
    state.isAddingRole = false
  },
  ADD_ROLE_FAILURE(state) {
    state.isAddingRole = false
  },
  UPDATE_ROLE_REQUEST(state) {
    state.isUpdatingRole = true
  },
  UPDATE_ROLE_SUCCESS(state) {
    state.isUpdatingRole = false
  },
  UPDATE_ROLE_FAILURE(state) {
    state.isUpdatingRole = false
  },
  DELETE_ROLE_REQUEST(state) {
    state.isDeletingRole = true
  },
  DELETE_ROLE_SUCCESS(state) {
    state.isDeletingRole = false
  },
  DELETE_ROLE_FAILURE(state) {
    state.isDeletingRole = false
  },

  FETCH_PERMISSIONS_REQUEST(state) {
    state.isFetchingPermissions = true
  },
  FETCH_PERMISSIONS_SUCCESS(state, payload) {
    state.isFetchingPermissions = false
    state.permissions = payload
  },
  FETCH_PERMISSIONS_FAILURE(state) {
    state.isFetchingPermission = false
  },
  FETCH_PERMISSION_REQUEST(state) {
    state.isFetchingPermission = true
  },
  FETCH_PERMISSION_SUCCESS(state, payload) {
    state.isFetchingPermission = false
    state.permissionDetail = payload
  },
  FETCH_PERMISSION_FAILURE(state) {
    state.isFetchingPermission = false
  },
  ADD_PERMISSION_REQUEST(state) {
    state.isAddingPermission = true
  },
  ADD_PERMISSION_SUCCESS(state) {
    state.isAddingPermission = false
  },
  ADD_PERMISSION_FAILURE(state) {
    state.isAddingPermission = false
  },
  UPDATE_PERMISSION_REQUEST(state) {
    state.isUpdatingPermission = true
  },
  UPDATE_PERMISSION_SUCCESS(state) {
    state.isUpdatingPermission = false
  },
  UPDATE_PERMISSION_FAILURE(state) {
    state.isUpdatingPermission = false
  },
  DELETE_PERMISSION_REQUEST(state) {
    state.isDeletingPermission = true
  },
  DELETE_PERMISSION_SUCCESS(state) {
    state.isDeletingPermission = false
  },
  DELETE_PERMISSION_FAILURE(state) {
    state.isDeletingPermission = false
  },
}
const actions = {
  async fetchRoles({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_ROLES_REQUEST')
      const response = await RoleService.fetchRoles(limit, page, search, masterId, agentId)
      commit('FETCH_ROLES_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ROLES_FAILURE', e.message)
    }
  },
  async fetchRole({ commit, dispatch }, id) {
    try {
      commit('FETCH_ROLE_REQUEST')
      const response = await RoleService.fetchRoleById(id)
      commit('FETCH_ROLE_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_ROLE_FAILURE', e.message)
    }
  },
  async createRole({ commit, dispatch }, data) {
    try {
      commit('ADD_ROLE_REQUEST')
      const response = await RoleService.createRole(data)
      commit('ADD_ROLE_SUCCESS', response)
      dispatch('fetchRoles', {limit: '', page: '', search: '', masterId: '', agentId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_ROLE_FAILURE', e.message)
    }
  },
  async updateRole({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_ROLE_REQUEST')
      const response = await RoleService.updateRole(id, data)
      commit('UPDATE_ROLE_SUCCESS', response)
      dispatch('fetchRole', id)
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_ROLE_FAILURE', e.message)
    }
  },
  async deleteRole({ commit, dispatch }, id) {
    try {
      commit('DELETE_ROLE_REQUEST')
      const response = await RoleService.deleteRole(id)
      commit('DELETE_ROLE_SUCCESS', response)
      dispatch('fetchRoles', {limit: '', page: '', search: '', masterId: '', agentId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_ROLE_FAILURE', e.message)
    }
  },
  async fetchPermissions({ commit, dispatch }) {
    try {
      commit('FETCH_PERMISSIONS_REQUEST')
      const response = await RoleService.fetchPermissions()
      commit('FETCH_PERMISSIONS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PERMISSIONS_FAILURE', e.message)
    }
  },
  async fetchPermission({ commit, dispatch }, { id }) {
    try {
      commit('FETCH_PERMISSION_REQUEST')
      const response = await RoleService.fetchPermission(id)
      commit('FETCH_PERMISSION_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PERMISSION_FAILURE', e.message)
    }
  },
  async addPermission({ commit, dispatch }, data) {
    try {
      commit('ADD_PERMISSION_REQUEST')
      const response = await RoleService.createPermission(data)
      commit('ADD_PERMISSION_SUCCESS', response)
      dispatch('fetchPermissions')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_PERMISSION_FAILURE', e.message)
    }
  },
  async updatePermission({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_PERMISSION_REQUEST')
      const response = await RoleService.updatePermission(id, data)
      commit('UPDATE_PERMISSION_SUCCESS', response)
      dispatch('notifySuccess', 'updated')
      dispatch('fetchPermissions')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_PERMISSION_FAILURE', e.message)
    }
  },
  async deletePermission({ commit, dispatch }, id) {
    try {
      commit('DELETE_PERMISSION_REQUEST')
      const response = await RoleService.deletePermission(id)
      commit('DELETE_PERMISSION_SUCCESS', response)
      dispatch('fetchPermissions')
      dispatch('notifySuccess', 'deleted')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_PERMISSION_FAILURE', e.message)
    }
  },
}
const getters = {
  roles: (state) => state.roles,
  roleDetail: (state) => state.roleDetail,

  permissions: (state) => state.permissions,
  permissionDetail: (state) => state.permissionDetail,
}
const state = {
  roles: [],
  isFetchingRoles: false,

  roleDetail: {},
  isFetchingRole: false,
  isAddingRoles: false,
  isUpdatingRole: false,
  isDeletingRole: false,

  permissions: [],
  isFetchingPermissions: false,

  permissionDetail: { data: [], total: 0, next: 0, previous: 0 },
  isFetchingPermission: false,
  isAddingPermissions: false,
  isUpdatingPermission: false,
  isDeletingPermission: false,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
