export default [
  {
    key: 'credit',
    icon: 'CreditCardIcon',
    path: '/credits',
    header: 'VIEW_CREDITS',
    name: 'VIEW_CREDITS',
    route: 'VIEW_CREDITS',
    meta: { 
      authRequired: true,
      isAppMenu: true,
    },
    redirect: '/credits/all',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        key: 'agent:view.credit-log',
        icon: '',
        path: 'all',
        name: 'VIEW_CREDIT_ALL',
        route: 'VIEW_CREDIT_ALL',
        component: () => import('@/views/credits/AllTransactions.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true,
        },
      },
      {
        key: 'agent:view.credit-log-agent',
        icon: '',
        path: 'agent',
        name: 'VIEW_CREDIT_MASTER',
        route: 'VIEW_CREDIT_MASTER',
        component: () => import('@/views/credits/AgentTransactions.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true, 
        },
      },
    ],
  },
]