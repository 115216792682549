export default [
  {
    key: 'agent:view.game',
    icon: 'PlayCircleIcon',
    path: '/games',
    name: 'VIEW_GAME',
    route: 'VIEW_GAME',
    header: 'GAMES',
    meta: { 
      authRequired: true,
      isAppMenu: true
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        key: 'agent:view.game',
        icon: 'PlayCircleIcon',
        path: 'settings',
        name: 'VIEW_GAME_SETTINGS',
        route: 'VIEW_GAME_SETTINGS',
        component: () => import('@/views/games/GameSettings.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true
        },
      },
      {
        key: 'agent:view.game',
        icon: 'SlidersIcon',
        path: 'history',
        name: 'VIEW_GAME_HISTORY',
        route: 'VIEW_GAME_HISTORY',
        component: () => import('@/views/games/GameHistory.vue'),
        meta: { 
          authRequired: true,
          isAppMenu: true
        },
      },
      {
        path: 'settings/:id',
        name: 'VIEW_GAME_DETAIL',
        component: () => import('@/views/games/GameDetail.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          breadCrumbs: [
            { href: '/games/settings', name: 'VIEW_GAME_SETTINGS' },
            { href: '/games/settings/:id', name: 'VIEW_GAME_DETAIL' },
          ],
        },
      },
      {
        path: 'create',
        name: 'CREATE_GAME',
        component: () => import('@/views/games/GameDetail.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          breadCrumbs: [
            { href: '/games/settings', name: 'VIEW_GAME_SETTINGS' },
            { href: '/games/create', name: 'CREATE_GAME' },
          ],
        },
      },
      {
        path: 'provider-games/:id',
        name: 'VIEW_GAME_PROVIDER_DETAIL',
        component: () => import('@/views/games/GameProviderDetail.vue'),
        meta: {
          authRequired: true,
          isAppMenu: false,
          breadCrumbs: [
            { href: '/games/settings', name: 'VIEW_GAME_SETTINGS' },
            {
              href: '/games/provider-games/:id',
              name: 'VIEW_GAME_PROVIDER_DETAIL',
            },
          ],
        },
      },
    ],
  },
]