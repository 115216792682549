import AffiliateService from '../../services/affiliate'

const mutations = {
  FETCH_AFFILIATE_SUMMARY_REQUEST(state) {
    state.isFetchingAffiliateSummary = true
  },
  FETCH_AFFILIATE_SUMMARY_SUCCESS(state, payload) {
    state.isFetchingAffiliateSummary = false
    state.affiliateSummary = payload
  },
  FETCH_AFFILIATE_SUMMARY_FAILURE(state) {
    state.isFetchingAffiliateSummary = false
  },
  FETCH_AGENT_AFFILIATE_REQUEST(state) {
    state.isFetchingAgentAffiliate = true
  },
  FETCH_AGENT_AFFILIATE_SUCCESS(state, payload) {
    state.isFetchingAgentAffiliate = false
    state.agentAfLevels = payload
  },
  FETCH_AGENT_AFFILIATE_FAILURE(state) {
    state.isFetchingAgentAffiliate = false
  },
  CREATE_AGENT_AFFILIATE_REQUEST(state) {
    state.isCreatingAgentAffiliate = true
  },
  CREATE_AGENT_AFFILIATE_SUCCESS(state, payload) {
    state.isCreatingAgentAffiliate = false
    state.agentAfLevels = payload
  },
  CREATE_AGENT_AFFILIATE_FAILURE(state) {
    state.isCreatingAgentAffiliate = false
  },
  UPDATE_AGENT_AFFILIATE_REQUEST(state) {
    state.isUpdatingAgentAffiliate = true
  },
  UPDATE_AGENT_AFFILIATE_SUCCESS(state) {
    state.isUpdatingAgentAffiliate = false
  },
  UPDATE_AGENT_AFFILIATE_FAILURE(state) {
    state.isUpdatingAgentAffiliate = false
  },
  DELETE_AGENT_AFFILIATE_REQUEST(state) {
    state.isDeletingAgentAffiliate = true
  },
  DELETE_AGENT_AFFILIATE_SUCCESS(state) {
    state.isDeletingAgentAffiliate = false
  },
  DELETE_AGENT_AFFILIATE_FAILURE(state) {
    state.isDeletingAgentAffiliate = false
  },
  FETCH_PLAYER_AFFILIATE_REQUEST(state) {
    state.isFetchingPlayerAffiliate = true
  },
  FETCH_PLAYER_AFFILIATE_SUCCESS(state, payload) {
    state.isFetchingPlayerAffiliate = false
    state.playerAfLevels = payload
  },
  FETCH_PLAYER_AFFILIATE_FAILURE(state) {
    state.isFetchingPlayerAffiliate = false
  },
  UPDATE_PLAYER_AFFILIATE_REQUEST(state) {
    state.isUpdatingPlayerAffiliate = true
  },
  UPDATE_PLAYER_AFFILIATE_SUCCESS(state, payload) {
    state.isUpdatingPlayerAffiliate = false
    state.playerAfLevels = payload
  },
  UPDATE_PLAYER_AFFILIATE_FAILURE(state) {
    state.isUpdatingPlayerAffiliate = false
  },
}
const actions = {
  async fetchAffiliateSummary({ commit, dispatch }) {
    try {
      commit('FETCH_AFFILIATE_SUMMARY_REQUEST')
      const result = await AffiliateService.fetchAffiliateSummary()
      commit('FETCH_AFFILIATE_SUMMARY_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AFFILIATE_SUMMARY_FAILURE', e.message)
    }
  },
  async fetchAgentAffiliate(
    { commit, dispatch },
    { agentId, gameId, gameType }
  ) {
    try {
      commit('FETCH_AGENT_AFFILIATE_REQUEST')
      const result = await AffiliateService.fetchAgentAffiliate(
        agentId,
        gameId,
        gameType
      )
      commit('FETCH_AGENT_AFFILIATE_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AGENT_AFFILIATE_FAILURE', e.message)
    }
  },
  async createAgentAffiliate({ commit, dispatch }, data) {
    try {
      commit('CREATE_AGENT_AFFILIATE_REQUEST')
      const result = await AffiliateService.createAgentAffiliate(data)
      commit('CREATE_AGENT_AFFILIATE_SUCCESS', result)
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_AGENT_AFFILIATE_FAILURE', e.message)
    }
  },
  async updateAgentAffiliate({ commit, dispatch }, data) {
    try {
      commit('UPDATE_AGENT_AFFILIATE_REQUEST')
      await AffiliateService.updateAgentAffiliate(data)
      commit('UPDATE_AGENT_AFFILIATE_SUCCESS')
      dispatch('fetchAgentAffiliate', {
        agentId: data.agent_id,
        gameId: data.game_id,
        gameType: data.game_type,
      })
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_AGENT_AFFILIATE_FAILURE', e.message)
    }
  },
  async deleteAgentAffiliate({ commit, dispatch }, id) {
    try {
      commit('DELETE_AGENT_AFFILIATE_REQUEST')
      await AffiliateService.deleteAgentAffiliate(id)
      commit('DELETE_AGENT_AFFILIATE_SUCCESS')
      dispatch('notifySuccess', 'deleted')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_AGENT_AFFILIATE_FAILURE', e.message)
    }
  },
  async fetchPlayerAffiliate(
    { commit, dispatch },
    { userId, agentId, gameId, gameType }
  ) {
    try {
      commit('FETCH_PLAYER_AFFILIATE_REQUEST')
      const result = await AffiliateService.fetchPlayerAffiliate(
        userId,
        agentId,
        gameId,
        gameType
      )
      commit('FETCH_PLAYER_AFFILIATE_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYER_AFFILIATE_FAILURE', e.message)
    }
  },
  async updatePlayerAffiliate({ commit, dispatch }, data) {
    try {
      commit('UPDATE_PLAYER_AFFILIATE_REQUEST')
      const result = await AffiliateService.updatePlayerAffiliate(data)
      commit('UPDATE_PLAYER_AFFILIATE_SUCCESS', result)
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_PLAYER_AFFILIATE_FAILURE', e.message)
    }
  },
}
const getters = {
  affiliateSummary: (state) => state.affiliateSummary,
  agentAfLevels: (state) => state.agentAfLevels,
  playerAfLevels: (state) => state.playerAfLevels,
}
const state = {
  affiliateSummary: {
    credit: 0,
    games: [],
    total_credit: 0,
    total_member: 0,
  },
  isFetchingAffiliateSummary: false,

  agentAfLevels: [],
  isFetchingAgentAffiliate: false,
  isCreatingAgentAffiliate: false,
  isUpdatingAgentAffiliate: false,
  isDeletingAgentAffiliate: false,

  playerAfLevels: [],
  isFetchingPlayerAffiliate: false,
  isUpdatingPlayerAffiliate: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
