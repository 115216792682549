import CashbackService from '../../services/cashback'

const mutations = {
  FETCH_CASHBACK_SETTINGS_REQUEST(state) {
    state.isFetchingCashbackSettings = true
  },
  FETCH_CASHBACK_SETTINGS_SUCCESS(state, payload) {
    state.isFetchingCashbackSettings = false
    state.cashbackSettings = payload
  },
  FETCH_CASHBACK_SETTINGS_FAILURE(state) {
    state.isFetchingCashbackSettings = false
  },
  FETCH_CASHBACK_SETTING_BY_AGENT_ID_REQUEST(state) {
    state.isFetchingCashbackSettingByAgentId = true
  },
  FETCH_CASHBACK_SETTING_BY_AGENT_ID_SUCCESS(state, payload) {
    state.isFetchingCashbackSettingByAgentId = false
    state.cashbackSettingByAgentId = payload
  },
  FETCH_CASHBACK_SETTING_BY_AGENT_ID_FAILURE(state) {
    state.isFetchingCashbackSettingByAgentId = false
  },
  ADD_CASHBACK_SETTINGS_REQUEST(state) {
    state.isAddingCashbackSettings = true
  },
  ADD_CASHBACK_SETTINGS_SUCCESS(state) {
    state.isAddingCashbackSettings = false
  },
  ADD_CASHBACK_SETTINGS_FAILURE(state) {
    state.isAddingCashbackSettings = false
  },
  UPDATE_CASHBACK_SETTINGS_REQUEST(state) {
    state.isUpdatingCashbackSettings = true
  },
  UPDATE_CASHBACK_SETTINGS_SUCCESS(state) {
    state.isUpdatingCashbackSettings = false
  },
  UPDATE_CASHBACK_SETTINGS_FAILURE(state) {
    state.isUpdatingCashbackSettings = false
  },
  DELETE_CASHBACK_SETTINGS_REQUEST(state) {
    state.isDeletingCashbackSettings = true
  },
  DELETE_CASHBACK_SETTINGS_SUCCESS(state) {
    state.isDeletingCashbackSettings = false
  },
  DELETE_CASHBACK_SETTINGS_FAILURE(state) {
    state.isDeletingCashbackSettings = false
  },
  FETCH_CASHBACK_TRANSACTION_REQUEST(state) {
    state.isFetchingCashbackTransaction = true
  },
  FETCH_CASHBACK_TRANSACTION_SUCCESS(state, payload) {
    state.isFetchingCashbackTransaction = false
    state.cashbackTransaction = payload
  },
  FETCH_CASHBACK_TRANSACTION_FAILURE(state) {
    state.isFetchingCashbackTransaction = false
  },
}
const actions = {
  async fetchCashbackSettings({ commit, dispatch }) {
    try {
      commit('FETCH_CASHBACK_SETTINGS_REQUEST')
      const response = await CashbackService.fetchCashbackSettings()
      commit('FETCH_CASHBACK_SETTINGS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CASHBACK_SETTINGS_FAILURE', e.message)
    }
  },
  async fetchCashbackSettingByAgentId({ commit, dispatch }, agentId) {
    try {
      commit('FETCH_CASHBACK_SETTING_BY_AGENT_ID_REQUEST')
      const response = await CashbackService.fetchCashbackSettingByAgentId(
        agentId
      )
      commit('FETCH_CASHBACK_SETTING_BY_AGENT_ID_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CASHBACK_SETTING_BY_AGENT_ID_FAILURE', e.message)
    }
  },
  async addCashbackSettings({ commit, dispatch }, data) {
    try {
      commit('ADD_CASHBACK_SETTINGS_REQUEST')
      const response = await CashbackService.createCashbackSettings(data)
      commit('ADD_CASHBACK_SETTINGS_SUCCESS', response)
      dispatch('fetchCashbackSettings')
      dispatch('notifySuccess', 'created')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_CASHBACK_SETTINGS_FAILURE', e.message)
    }
  },
  async updateCashbackSettings({ commit, dispatch }, { id, form }) {
    try {
      commit('UPDATE_CASHBACK_SETTINGS_REQUEST')
      const response = await CashbackService.updateCashbackSettings(id, form)
      commit('UPDATE_CASHBACK_SETTINGS_SUCCESS', response)
      dispatch('fetchCashbackSettings')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_CASHBACK_SETTINGS_FAILURE', e.message)
    }
  },
  async deleteCashbackSettings({ commit, dispatch }, id) {
    try {
      commit('DELETE_CASHBACK_SETTINGS_REQUEST')
      const response = await CashbackService.deleteCashbackSettings(id)
      commit('DELETE_CASHBACK_SETTINGS_SUCCESS', response)
      dispatch('fetchCashbackSettings')
      dispatch('notifySuccess', 'deleted')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_CASHBACK_SETTINGS_FAILURE', e.message)
    }
  },
  async fetchCashbackTransaction({ commit, dispatch }, { limit, page }) {
    try {
      commit('FETCH_CASHBACK_TRANSACTION_REQUEST')
      const response = await CashbackService.fetchCashbackTransaction(
        limit,
        page
      )
      commit('FETCH_CASHBACK_TRANSACTION_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_CASHBACK_TRANSACTION_FAILURE', e.message)
    }
  },
}
const getters = {
  cashbackSettings: (state) => state.cashbackSettings,
  cashbackSettingByAgentId: (state) => state.cashbackSettingByAgentId,
  cashbackTransaction: (state) => state.cashbackTransaction,
}
const state = {
  cashbackSettings: [],
  isFetchingCashbackSettings: false,
  isAddingCashbackSettings: false,
  isUpdatingCashbackSettings: false,
  isDeletingCashbackSettings: false,

  isFetchingCashbackSettingByAgentId: false,
  cashbackSettingByAgentId: {},

  cashbackTransaction: [],
  isFetchingCashbackTransaction: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
