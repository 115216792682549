import router from '../../router/index'
import UserService from '../../services/user'

const mutations = {
  GET_ME_REQUEST(state) {
    state.isGettingMe = true
  },
  GET_ME_SUCCESS(state, payload) {
    state.isGettingMe = false
    state.isGettingMeError = true
    state.userInfo = payload
  },
  GET_ME_FAILURE(state) {
    state.isGettingMe = false
    state.isGettingMeError = true
  },
  FETCH_USERS_REQUEST(state) {
    state.isFetchingUsers = true
    state.isFetchingUsersError = false
    state.isFetchingUsersSuccess = false
  },
  FETCH_USERS_SUCCESS(state, payload) {
    state.isFetchingUsers = false
    state.isFetchingUsersSuccess = true
    state.users = payload
  },
  FETCH_USERS_FAILURE(state) {
    state.isFetchingUsers = false
    state.isFetchingUsersError = true
  },
  UPDATE_PROFILE_REQUEST(state) {
    state.isUpdatingProfile = true
    state.isUpdatingProfileError = false
    state.isUpdatingProfileSuccess = false
  },
  UPDATE_PROFILE_SUCCESS(state) {
    state.isUpdatingProfile = false
    state.isUpdatingProfileSuccess = true
  },
  UPDATE_PROFILE_FAILURE(state) {
    state.isUpdatingProfile = false
    state.isUpdatingProfileError = true
  },
  RESET_PASSWORD_USER_REQUEST(state) {
    state.isResettingPasswordUser = true
    state.isResettingPasswordUserError = false
    state.isResettingPasswordUserSuccess = false
  },
  RESET_PASSWORD_USER_SUCCESS(state) {
    state.isResettingPasswordUser = false
    state.isResettingPasswordUserSuccess = true
  },
  RESET_PASSWORD_USER_FAILURE(state, payload) {
    state.isResettingPasswordUser = false
    state.isResettingPasswordUserError = payload
  },
  FETCH_BANK_ACCOUNTS_REQUEST(state) {
    state.isFetchingBankAccounts = true
    state.isFetchingBankAccountsError = false
    state.isFetchingBankAccountsSuccess = false
  },
  FETCH_BANK_ACCOUNTS_SUCCESS(state, payload) {
    state.isFetchingBankAccounts = false
    state.isFetchingBankAccountsSuccess = true
    state.userBankAccounts = payload
  },
  FETCH_BANK_ACCOUNTS_FAILURE(state) {
    state.isFetchingBankAccounts = false
    state.isFetchingBankAccountsError = true
  },
  FETCH_BANK_ACCOUNT_REQUEST(state) {
    state.isFetchingBankAccount = true
    state.isFetchingBankAccountError = false
    state.isFetchingBankAccountSuccess = false
  },
  FETCH_BANK_ACCOUNT_SUCCESS(state, payload) {
    state.isFetchingBankAccount = false
    state.isFetchingBankAccountSuccess = true
    state.userBankAccountDetail = payload
  },
  FETCH_BANK_ACCOUNT_FAILURE(state) {
    state.isFetchingBankAccount = false
    state.isFetchingBankAccountError = true
  },
  ADD_BANK_ACCOUNT_REQUEST(state) {
    state.isAddingBankAccount = true
    state.isAddingBankAccountError = false
    state.isAddingBankAccountSuccess = false
  },
  ADD_BANK_ACCOUNT_SUCCESS(state) {
    state.isAddingBankAccount = false
    state.isAddingBankAccountSuccess = true
  },
  ADD_BANK_ACCOUNT_FAILURE(state) {
    state.isAddingBankAccount = false
    state.isAddingBankAccountError = true
  },
  UPDATE_BANK_ACCOUNT_REQUEST(state) {
    state.isUpdatingBankAccount = true
    state.isUpdatingBankAccountError = false
    state.isUpdatingBankAccountSuccess = false
  },
  UPDATE_BANK_ACCOUNT_SUCCESS(state) {
    state.isUpdatingBankAccount = false
    state.isUpdatingBankAccountSuccess = true
  },
  UPDATE_BANK_ACCOUNT_FAILURE(state) {
    state.isUpdatingBankAccount = false
    state.isUpdatingBankAccountError = true
  },
  FETCH_WITHDRAW_SETTINGS_REQUEST(state) {
    state.isFetchingWithdrawSettings = true
    state.isFetchingWithdrawSettingsError = false
    state.isFetchingWithdrawSettingsSuccess = false
  },
  FETCH_WITHDRAW_SETTINGS_SUCCESS(state, payload) {
    state.isFetchingWithdrawSettings = false
    state.isFetchingWithdrawSettingsSuccess = true
    state.withdrawSettings = payload
  },
  FETCH_WITHDRAW_SETTINGS_FAILURE(state) {
    state.isFetchingWithdrawSettings = false
    state.isFetchingWithdrawSettingsError = true
  },
  UPDATE_WITHDRAW_SETTINGS_REQUEST(state) {
    state.isUpdatingWithdrawSettings = true
    state.isUpdatingWithdrawSettingsError = false
    state.isUpdatingWithdrawSettingsSuccess = false
  },
  UPDATE_WITHDRAW_SETTINGS_SUCCESS(state) {
    state.isUpdatingWithdrawSettings = false
    state.isUpdatingWithdrawSettingsSuccess = true
  },
  UPDATE_WITHDRAW_SETTINGS_FAILURE(state) {
    state.isUpdatingWithdrawSettings = false
    state.isUpdatingWithdrawSettingsError = true
  },
  FETCH_DASHBOARD_REQUEST(state) {
    state.isFetchingDashboard = true
  },
  FETCH_DASHBOARD_SUCCESS(state, payload) {
    state.isFetchingDashboard = false
    state.dashboard = { ...state.dashboard, ...payload }
  },
  FETCH_DASHBOARD_FAILURE(state) {
    state.isFetchingDashboard = false
  },
  FETCH_USER_ACCESS_REQUEST(state) {
    state.isFetchingUserAccess = true
  },
  FETCH_USER_ACCESS_SUCCESS(state, payload) {
    state.isFetchingUserAccess = false
    state.userAccess = payload
  },
  FETCH_USER_ACCESS_FAILURE(state) {
    state.isFetchingUserAccess = false
  },
  DELETE_BANK_ACCOUNT_REQUEST(state) {
    state.isDeletingBankAccount = true
    state.isDeletingBankAccountError = ''
  },
  DELETE_BANK_ACCOUNT_SUCCESS(state) {
    state.isDeletingBankAccount = false
  },
  DELETE_BANK_ACCOUNT_FAILURE(state, payload) {
    state.isDeletingBankAccount = false
    state.isDeletingBankAccountError = payload
  },
  REGISTER_SCB_REQUEST(state) {
    state.isRegisteringSCB = true
    state.isRegisteringSCBSuccess = false
    state.isRegisteringSCBError = ''
  },
  REGISTER_SCB_SUCCESS(state) {
    state.isRegisteringSCB = false
    state.isRegisteringSCBSuccess = true
  },
  REGISTER_SCB_FAILURE(state, payload) {
    state.isRegisteringSCB = false
    state.isRegisteringSCBError = payload
  },
  OTP_VERIFY_REQUEST(state) {
    state.isVerifyingOTP = true
    state.isVerifyingOTPSuccess = false
    state.isVerifyingOTPError = ''
  },
  OTP_VERIFY_SUCCESS(state) {
    state.isVerifyingOTPSuccess = true
    state.isVerifyingOTP = false
  },
  OTP_VERIFY_FAILURE(state, payload) {
    state.isVerifyingOTP = false
    state.isVerifyingOTPError = payload
  },
  CLEAR_BANK_ACCOUNT_FORM(state) {
    state.userBankAccountDetail = {}
  },
  REGISTER_TRUE_WALLET_REQUEST(state) {
    state.isRegisteringTrueWallet = true
    state.isRegisteringTrueWalletSuccess = false
  },
  REGISTER_TRUE_WALLET_SUCCESS(state, ref) {
    state.isRegisteringTrueWallet = false
    state.isRegisteringTrueWalletSuccess = true
    state.registerTrueWalletRef = ref
  },
  REGISTER_TRUE_WALLET_FAILURE(state) {
    state.isRegisteringTrueWallet = false
    state.registerTrueWalletRef = ''
  },
  VERIFY_TRUE_WALLET_REQUEST(state) {
    state.isVerifyingTrueWallet = true
    state.isVerifyingTrueWalletSuccess = false
  },
  VERIFY_TRUE_WALLET_SUCCESS(state) {
    state.isVerifyingTrueWallet = false
    state.isVerifyingTrueWalletSuccess = true
  },
  VERIFY_TRUE_WALLET_FAILURE(state) {
    state.isVerifyingTrueWallet = false
  },
  FETCH_SUMMARY_REQUEST(state) {
    state.isFetchingSummary = true
  },
  FETCH_SUMMARY_SUCCESS(state, payload) {
    state.isFetchingSummary = false
    state.summary= payload
  },
  FETCH_SUMMARY_FAILURE(state) {
    state.isFetchingSummary = false
  },
}
const actions = {
  async getMe({ commit, dispatch }) {
    try {
      commit('GET_ME_REQUEST')
      const response = await UserService.getMe()
      commit('GET_ME_SUCCESS', response)
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('GET_ME_FAILURE')
    }
  },
  async fetchUsers({ commit }) {
    try {
      commit('FETCH_USERS_REQUEST')
      const response = await UserService.getUsers()
      commit('FETCH_USERS_SUCCESS', response)
    } catch (e) {
      commit('FETCH_USERS_FAILURE', e.message)
    }
  },
  async updateProfile({ commit, dispatch }, data) {
    try {
      commit('UPDATE_PROFILE_REQUEST')
      await UserService.updateProfile(data)
      commit('UPDATE_PROFILE_SUCCESS')
      dispatch('app/notifySuccess', 'updated')
      dispatch('getMe')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('UPDATE_PROFILE_FAILURE', e.message)
    }
  },
  async resetPasswordUser({ commit, dispatch }, data) {
    try {
      commit('RESET_PASSWORD_USER_REQUEST')
      await UserService.resetPassword(data)
      commit('RESET_PASSWORD_USER_SUCCESS')
      dispatch('app/notifySuccess', 'updated')
    } catch (e) {
      dispatch('app/notifyError', e)
      commit('RESET_PASSWORD_USER_FAILURE', e.message)
    }
  },
  async fetchBankAccounts({ commit, dispatch }, {limit = '', page = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_BANK_ACCOUNTS_REQUEST')
      const result = await UserService.fetchBankAccounts(limit, page, masterId, agentId)
      commit('FETCH_BANK_ACCOUNTS_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BANK_ACCOUNTS_FAILURE', e.message)
    }
  },
  async fetchBankAccount({ commit, dispatch }, id) {
    try {
      commit('FETCH_BANK_ACCOUNT_REQUEST')
      const result = await UserService.fetchBankAccountById(id)
      commit('FETCH_BANK_ACCOUNT_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async addBankAccount({ commit, dispatch }, data) {
    try {
      commit('ADD_BANK_ACCOUNT_REQUEST')
      const { id } = await UserService.addBankAccount(data)
      commit('ADD_BANK_ACCOUNT_SUCCESS')
      dispatch('notifySuccess', 'created')
      await router.push(`/bank-accounts/detail/${id}`)
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async registerSCB({ commit, dispatch }, { id, data }) {
    try {
      commit('REGISTER_SCB_REQUEST')
      await UserService.registerSCB(id, data)
      commit('REGISTER_SCB_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('REGISTER_SCB_FAILURE', e.message)
    }
  },
  async verifyScbOTP({ commit, dispatch }, { id, data }) {
    try {
      commit('OTP_VERIFY_REQUEST')
      await UserService.verifyScbOTP(id, data)
      commit('OTP_VERIFY_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('OTP_VERIFY_FAILURE', e.message)
    }
  },
  async registerTrueWallet({ commit, dispatch }, { id, form }) {
    try {
      commit('REGISTER_TRUE_WALLET_REQUEST')
      await UserService.registerTrueWallet(id, form)
      commit('REGISTER_TRUE_WALLET_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('REGISTER_TRUE_WALLET_FAILURE')
    }
  },
  async verifyTrueWalletOTP({ commit, dispatch }, { id, data }) {
    try {
      commit('VERIFY_TRUE_WALLET_REQUEST')
      await UserService.verifyTrueWallet(id, data)
      dispatch('notifySuccess')
      commit('VERIFY_TRUE_WALLET_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('VERIFY_TRUE_WALLET_FAILURE')
    }
  },
  async updateBankAccount({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_BANK_ACCOUNT_REQUEST')
      await UserService.updateBankAccount(id, data)
      commit('UPDATE_BANK_ACCOUNT_SUCCESS')
      dispatch('fetchBankAccount', id)
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async deleteBankAccount({ commit, dispatch }, id) {
    try {
      commit('DELETE_BANK_ACCOUNT_REQUEST')
      await UserService.deleteBankAccount(id)
      commit('DELETE_BANK_ACCOUNT_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_BANK_ACCOUNT_FAILURE', e.message)
    }
  },
  async fetchWithdrawSettings({ commit, dispatch }) {
    try {
      commit('FETCH_WITHDRAW_SETTINGS_REQUEST')
      const result = await UserService.fetchWithdrawSettings()
      commit('FETCH_WITHDRAW_SETTINGS_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAW_SETTINGS_FAILURE', e.message)
    }
  },
  async updateWithdrawSettings({ commit, dispatch }, data) {
    try {
      commit('UPDATE_WITHDRAW_SETTINGS_REQUEST')
      await UserService.withdrawSettings(data)
      commit('UPDATE_WITHDRAW_SETTINGS_SUCCESS')
      dispatch('fetchWithdrawSettings')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_WITHDRAW_SETTINGS_FAILURE', e.message)
    }
  },
  async fetchDashboard({ commit }, { date, masterId, agentId }) {
    try {
      commit('FETCH_DASHBOARD_REQUEST')
      const result = await UserService.fetchDashboard(date, masterId, agentId)
      commit('FETCH_DASHBOARD_SUCCESS', result)
    } catch (e) {
      commit('FETCH_DASHBOARD_FAILURE', e.message)
    }
  },
  async fetchUserAccess(
    { commit, dispatch },
    { limit = '', page = '', search = '', masterId = '', agentId = '' }
  ) {
    try {
      commit('FETCH_USER_ACCESS_REQUEST')
      const response = await UserService.fetchUserAccess(
        limit,
        page,
        search,
        masterId,
        agentId,
      )
      commit('FETCH_USER_ACCESS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_USER_ACCESS_FAILURE', e.message)
    }
  },
  async clearBankAccountForm({ commit }) {
    commit('CLEAR_BANK_ACCOUNT_FORM')
  },
  async fetchSummary({ commit }, { search, masterId, agentId, from, to }) {
    try {
      commit('FETCH_SUMMARY_REQUEST')
      const result = await UserService.fetchSummary(search, masterId, agentId, from, to)
      commit('FETCH_SUMMARY_SUCCESS', result)
    } catch (e) {
      commit('FETCH_SUMMARY_FAILURE', e.message)
    }
  },
}

const getters = {
  users: (state) => state.users,
  userInfo: (state) => state.userInfo,
  // available roles ['MASTER', 'AGENT']
  // affects > menu
  adminRole: (state) => state.userInfo.roleName,
  adminPermissions: (state) => state.userInfo.permissions,
  debugPermissions: (state) => state.userInfo.permissions.map((perm) => perm.key),
  isOwner: (state) => state.userInfo.roleName === 'OWNER',
  isMaster: (state) => state.userInfo.roleName === 'MASTER',
  isAgent: (state) => state.userInfo.roleName === 'AGENT',
  userBankAccounts: (state) => state.userBankAccounts,
  userBankAccountDetail: (state) => state.userBankAccountDetail,
  withdrawSettings: (state) => state.withdrawSettings,
  dashboard: (state) => state.dashboard,
  userAccess: (state) => state.userAccess,
  summary: (state) => state.summary,
}
const state = {
  userInfo: { username: '', permissions: [], roleName: '', credit: 0, masters: [] },
  isGettingMe: false,
  isGettingMeError: false,

  dashboard: {
    total: {
      deposit: 0,
      register_count: 0,
      withdraw: 0,
      manual_deposit: 0,
      manual_withdraw: 0,
      all_time_amount: 0,
      all_time_count: 0,
      free_credit_count: 0, // รับเครดิตฟรี (จำนวนครั้ง)
      free_credit_spent: 0, // เครดิตฟรีที่เสียทั้งหมด (เครดิตรวม)
      special_time_amount: 0, //
      special_time_count: 0, //
      first_deposit_amount: 0, // ยอดฝากครั้งแรก (เครดิตรวม)
      first_deposit_count: 0, // ยอดฝากครั้งแรก(จำนวนครั้ง)
      first_deposit_in_day_amount: 0, // ฝากครั้งแรกของวัน (เครดิตรวม)
      first_deposit_in_day_count: 0, // ฝากครั้งแรกของวัน (จำนวนครั้ง)
    },
    today: {
      deposit: 0, // ยอดฝากรวมวันนี้ (เครดิต)
      register_count: 0,
      withdraw: 0, // ยอดถอนรวมวันนี้ (เครดิต)
      manual_deposit: 0, // ยอดฝากตรง
      manual_withdraw: 0, // ยอดถอนตรง
      all_time_amount: 0, // สรุปยอดทั้งหมด (เครดิตรวม)
      all_time_count: 0, // สรุปยอดทั้งหมด (จำนวนครั้ง)
      free_credit_count: 0, // รับเครดิตฟรี (จำนวนครั้ง)
      free_credit_spent: 0, // เครดิตฟรีที่เสียทั้งหมด (เครดิตรวม)
      special_time_amount: 0, //
      special_time_count: 0, //
      first_deposit_amount: 0, // ยอดฝากครั้งแรก (เครดิตรวม)
      first_deposit_count: 0, // ยอดฝากครั้งแรก(จำนวนครั้ง)
      first_deposit_in_day_amount: 0, // ฝากครั้งแรกของวัน (เครดิตรวม)
      first_deposit_in_day_count: 0, // ฝากครั้งแรกของวัน (จำนวนครั้ง)
    },
  },
  isFetchingDashboard: false,

  users: [],
  isFetchingUsers: false,
  isFetchingUsersError: false,
  isFetchingUsersSuccess: false,

  isUpdatingProfile: false,
  isUpdatingProfileError: false,
  isUpdatingProfileSuccess: false,

  isResettingPasswordUser: false,
  isResettingPasswordUserError: '',
  isResettingPasswordUserSuccess: false,

  userBankAccounts: { data: [], total: 0, next: 0, previous: 0 },
  isFetchingBankAccounts: false,
  isFetchingBankAccountsError: false,
  isFetchingBankAccountsSuccess: false,

  userBankAccountDetail: {},
  isFetchingBankAccount: false,
  isFetchingBankAccountError: false,
  isFetchingBankAccountSuccess: false,

  isAddingBankAccount: false,
  isAddingBankAccountError: false,
  isAddingBankAccountSuccess: false,
  isUpdatingBankAccount: false,
  isUpdatingBankAccountError: false,
  isUpdatingBankAccountSuccess: false,

  withdrawSettings: [],
  isFetchingWithdrawSettings: false,
  isFetchingWithdrawSettingsError: false,
  isFetchingWithdrawSettingsSuccess: false,
  isUpdatingWithdrawSettings: false,
  isUpdatingWithdrawSettingsError: false,
  isUpdatingWithdrawSettingsSuccess: false,

  userAccess: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  isFetchingUserAccess: true,

  isDeletingBankAccount: false,
  isDeletingBankAccountError: '',

  isRegisteringSCB: false,
  isRegisteringSCBSuccess: false,
  isRegisteringSCBError: '',

  isVerifyingOTP: false,
  isVerifyingOTPSuccess: false,
  isVerifyingOTPError: '',

  isRegisteringTrueWallet: false,
  isRegisteringTrueWalletSuccess: false,
  registerTrueWalletRef: '',

  isVerifyingTrueWallet: false,
  isVerifyingTrueWalletSuccess: false,

  isFetchingSummary: false,
  summary: [],
}
export default {
  state,
  mutations,
  actions,
  getters,
}
