import PromotionService from '../../services/promotion'
import router from '../../router/index'

const mutations = {
  FETCH_PROMOTIONS_REQUEST(state) {
    state.isFetchingPromotions = true
  },
  FETCH_PROMOTIONS_SUCCESS(state, payload) {
    state.isFetchingPromotions = false
    state.promotions = payload
  },
  FETCH_PROMOTIONS_FAILURE(state) {
    state.isFetchingPromotions = false
  },
  FETCH_PROMOTION_REQUEST(state) {
    state.isFetchingPromotion = true
  },
  FETCH_PROMOTION_SUCCESS(state, payload) {
    state.isFetchingPromotion = false
    state.promotionDetail = payload
  },
  FETCH_PROMOTION_FAILURE(state) {
    state.isFetchingPromotion = false
  },
  ADD_PROMOTION_REQUEST(state) {
    state.isAddingPromotion = true
  },
  ADD_PROMOTION_SUCCESS(state) {
    state.isAddingPromotion = false
  },
  ADD_PROMOTION_FAILURE(state) {
    state.isAddingPromotion = false
  },
  UPDATE_PROMOTION_REQUEST(state) {
    state.isUpdatingPromotion = true
  },
  UPDATE_PROMOTION_SUCCESS(state, payload) {
    state.isUpdatingPromotion = false
    state.promotionDetail = payload
  },
  UPDATE_PROMOTION_FAILURE(state) {
    state.isUpdatingPromotion = false
  },
  UPDATE_PROMOTION_ORDER_REQUEST(state) {
    state.isUpdatingPromotionOrder = true
  },
  UPDATE_PROMOTION_ORDER_SUCCESS(state) {
    state.isUpdatingPromotionOrder = false
  },
  UPDATE_PROMOTION_ORDER_FAILURE(state) {
    state.isUpdatingPromotionOrder = false
  },
  DELETE_PROMOTION_REQUEST(state) {
    state.isDeletingPromotion = true
  },
  DELETE_PROMOTION_SUCCESS(state) {
    state.isDeletingPromotion = false
  },
  DELETE_PROMOTION_FAILURE(state) {
    state.isDeletingPromotion = false
  },
}
const actions = {
  async fetchPromotions({ commit, dispatch }, {limit, page, search, agentId, masterId}) {
    try {
      commit('FETCH_PROMOTIONS_REQUEST')
      const response = await PromotionService.fetchPromotions(limit, page, search, agentId, masterId)
      commit('FETCH_PROMOTIONS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PROMOTIONS_FAILURE', e.message)
    }
  },
  async fetchPromotion({ commit, dispatch }, id) {
    try {
      commit('FETCH_PROMOTION_REQUEST')
      const response = await PromotionService.fetchPromotionById(id)
      commit('FETCH_PROMOTION_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PROMOTION_FAILURE', e.message)
    }
  },
  async addPromotion({ commit, dispatch }, data) {
    try {
      commit('ADD_PROMOTION_REQUEST')
      const result = await PromotionService.addPromotion(data)
      commit('ADD_PROMOTION_SUCCESS', result)
      dispatch('fetchPromotions', {limit: '', page: '', search: '', agentId: '', masterId: ''})
      dispatch('notifySuccess', 'created')
      router.push('/promotions/list')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_PROMOTION_FAILURE', e.message)
    }
  },
  async updatePromotion({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_PROMOTION_REQUEST')
      const result = await PromotionService.updatePromotion(id, data)
      commit('UPDATE_PROMOTION_SUCCESS', result)
      dispatch('fetchPromotion', id)
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_PROMOTION_FAILURE', e.message)
    }
  },
  async updatePromotionOrder({ commit, dispatch }, { id, data }) {
    try {
      commit('UPDATE_PROMOTION_ORDER_REQUEST')
      const result = await PromotionService.updatePromotionOrder(id, data)
      commit('UPDATE_PROMOTION_ORDER_SUCCESS', result)
      dispatch('fetchPromotions', {limit: '', page: '', search: '', agentId: '', masterId: ''})
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_PROMOTION_ORDER_FAILURE', e.message)
    }
  },
  async deletePromotion({ commit, dispatch }, id) {
    try {
      commit('DELETE_PROMOTION_REQUEST')
      const response = await PromotionService.deletePromotion(id)
      commit('DELETE_PROMOTION_SUCCESS', response)
      dispatch('notifySuccess', 'deleted')
      dispatch('fetchPromotions', {limit: '', page: '', search: '', agentId: '', masterId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_PROMOTION_FAILURE', e.message)
    }
  },
}
const getters = {
  promotions: (state) => state.promotions,
  promotionDetail: (state) => state.promotionDetail,
}
const state = {
  promotions: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingPromotions: false,

  promotionDetail: {},
  isFetchingPromotion: false,
  isAddingPromotion: false,
  isUpdatingPromotion: false,
  isUpdatingPromotionOrder: false,
  isDeletingPromotion: false,

}
export default {
  state,
  mutations,
  actions,
  getters,
}
