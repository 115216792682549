import axios from 'axios'
const baseURL = `${process.env.VUE_APP_NEW_API_URL}/withdraw`

export default {
  fetchWithdraws(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`)
    } catch (e) {
      throw e
    }
  },
  fetchWithdrawDone(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/done?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`)
    } catch (e) {
      throw e
    }
  },
  fetchWithdrawHide(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/hide?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`)
    } catch (e) {
      throw e
    }
  },
  rejectWithdraw(id, data) {
    try {
      return axios.post(`${baseURL}/reject/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  requestWithdrawOtp(id, agentBankAccountId) {
    try {
      return axios.post(`${baseURL}/otp/${id}`, { agentBankAccountId })
    } catch (e) {
      throw e
    }
  },
  approveWithdraw(id, data) {
    try {
      return axios.post(`${baseURL}/approve/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  hideWithdraw(id) {
    try {
      return axios.post(`${baseURL}/hide/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchBankAccounts() {
    try {
      return axios.get(`${baseURL}/bank-accounts`)
    } catch (e) {
      throw e
    }
  },
}
