export default [
  {
    key: 'agent:view.dashboard',
    icon: 'HomeIcon',
    path: '/dashboard',
    name: 'dashboard',
    route: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: true,
    },
  },
]
