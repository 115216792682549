import router from '../../router/index'
import AgentService from '../../services/agent'
import UserService from '../../services/user'

const mutations = {
  FETCH_AGENTS_REQUEST(state) {
    state.isFetchingAgents = true
  },
  FETCH_AGENTS_SUCCESS(state, payload) {
    state.isFetchingAgents = false
    state.agents = payload
  },
  FETCH_AGENTS_FAILURE(state) {
    state.isFetchingAgents = false
  },
  FETCH_AGENTS_BY_MASTER_REQUEST(state) {
    state.isFetchingAgentsByMaster = true
  },
  FETCH_AGENTS_BY_MASTER_SUCCESS(state, payload) {
    state.isFetchingAgentsByMaster = false
    state.agentsByMaster = payload
  },
  FETCH_AGENTS_BY_MASTER_FAILURE(state) {
    state.isFetchingAgentsByMaster = false
  },
  FETCH_MASTERS_REQUEST(state) {
    state.isFetchingMasters = true
  },
  FETCH_MASTERS_SUCCESS(state, payload) {
    state.isFetchingMasters = false
    state.masters = payload
  },
  FETCH_MASTERS_FAILURE(state) {
    state.isFetchingMasters = false
  },
  FETCH_AGENT_REQUEST(state) {
    state.isFetchingAgent = true
  },
  FETCH_AGENT_SUCCESS(state, payload) {
    state.isFetchingAgent = false
    state.agentDetail = payload
  },
  FETCH_AGENT_FAILURE(state) {
    state.isFetchingAgent = false
  },
  CREATE_AGENT_REQUEST(state) {
    state.isCreatingAgent = true
  },
  CREATE_AGENT_SUCCESS(state) {
    state.isCreatingAgent = false
  },
  CREATE_AGENT_FAILURE(state) {
    state.isCreatingAgent = false
  },
  UPDATE_AGENT_REQUEST(state) {
    state.isUpdatingAgent = true
  },
  UPDATE_AGENT_SUCCESS(state) {
    state.isUpdatingAgent = false
  },
  UPDATE_AGENT_FAILURE(state) {
    state.isUpdatingAgent = false
  },
  LOGIN_AS_AGENT_REQUEST(state) {
    state.isLoggingInAsAgent = true
  },
  LOGIN_AS_AGENT_SUCCESS(state) {
    state.isLoggingInAsAgent = false
  },
  LOGIN_AS_AGENT_FAILURE(state) {
    state.isLoggingInAsAgent = false
  },
  DEPOSIT_AGENT_CREDIT_REQUEST(state) {
    state.isDepositingAgentCredit = true
  },
  DEPOSIT_AGENT_CREDIT_SUCCESS(state) {
    state.isDepositingAgentCredit = false
  },
  DEPOSIT_AGENT_CREDIT_FAILURE(state) {
    state.isDepositingAgentCredit = false
  },
  WITHDRAW_AGENT_CREDIT_REQUEST(state) {
    state.isWithdrawingAgentCredit = true
  },
  WITHDRAW_AGENT_CREDIT_SUCCESS(state) {
    state.isWithdrawingAgentCredit = false
  },
  WITHDRAW_AGENT_CREDIT_FAILURE(state) {
    state.isWithdrawingAgentCredit = false
  },
  DELETE_AGENT_REQUEST(state) {
    state.isDeletingAgent = true
  },
  DELETE_AGENT_SUCCESS(state) {
    state.isDeletingAgent = false
  },
  DELETE_AGENT_FAILURE(state) {
    state.isDeletingAgent = false
  },
}
const actions = {
  async fetchAgents({ commit, dispatch }, { limit = '', page = '', search = '', masterId = '' }) {
    try {
      commit('FETCH_AGENTS_REQUEST')
      const response = await AgentService.fetchAgents(limit, page, search, masterId)
      commit('FETCH_AGENTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AGENTS_FAILURE', e.message)
    }
  },
  async fetchMasters({ commit, dispatch }, { limit = '', page = '', search = '', masterId = '' }) {
    try {
      commit('FETCH_MASTERS_REQUEST')
      const response = await AgentService.fetchAgents(limit, page, search, masterId)
      commit('FETCH_MASTERS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_MASTERS_FAILURE', e.message)
    }
  },
  async fetchAgentsByMaster({ commit, dispatch }, { limit = '', page = '', search = '', masterId = '' }) {
    try {
      commit('FETCH_AGENTS_BY_MASTER_REQUEST')
      const response = await AgentService.fetchAgents(limit, page, search, masterId)
      commit('FETCH_AGENTS_BY_MASTER_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AGENTS_BY_MASTER_FAILURE', e.message)
    }
  },
  async fetchAgent({ commit, dispatch }, id) {
    try {
      commit('FETCH_AGENT_REQUEST')
      const response = await AgentService.fetchAgentById(id)
      commit('FETCH_AGENT_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_AGENT_FAILURE')
    }
  },
  async createAgent({ commit, dispatch }, data) {
    try {
      commit('CREATE_AGENT_REQUEST')
      await AgentService.createAgent(data)
      commit('CREATE_AGENT_SUCCESS')
      dispatch('notifySuccess', 'created')
      router.push('/agents/list')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_AGENT_FAILURE', e.message)
    }
  },
  async updateAgent({ commit, dispatch }, { id, data }) {
    try {
      await AgentService.updateAgent(id, data)
      commit('UPDATE_AGENT_SUCCESS')
      dispatch('fetchAgent', id)
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_AGENT_FAILURE', e.message)
    }
  },
  async loginAsAgent({ commit, dispatch }, agentId) {
    try {
      commit('LOGIN_AS_AGENT_REQUEST')
      const { token } = await AgentService.loginAsAgent(agentId)
      if(token){
        UserService.saveAccessToken(token)
          commit('LOGIN_SUCCESS', token)
          await dispatch('init')
          const { path } = router.currentRoute
          if (path !== '/dashboard') {
            router.replace('/dashboard')
          }
      }
      commit('LOGIN_AS_AGENT_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('LOGIN_AS_AGENT_FAILURE', e.message)
    }
  },
  async depositAgentCredit({ commit, dispatch }, {agentId, data}) {
    try {
      commit('DEPOSIT_AGENT_CREDIT_REQUEST')
      await AgentService.depositAgentCredit(agentId, data)
      commit('DEPOSIT_AGENT_CREDIT_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DEPOSIT_AGENT_CREDIT_FAILURE', e.message)
    }
  },
  async withdrawAgentCredit({ commit, dispatch }, {agentId, data}) {
    try {
      commit('WITHDRAW_AGENT_CREDIT_REQUEST')
      await AgentService.withdrawAgentCredit(agentId, data)
      commit('WITHDRAW_AGENT_CREDIT_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('WITHDRAW_AGENT_CREDIT_FAILURE', e.message)
    }
  },
  async deleteAgent({ commit, dispatch }, agentId) {
    try {
      commit('DELETE_AGENT_REQUEST')
      await AgentService.deleteAgent(agentId)
      commit('DELETE_AGENT_SUCCESS')
      dispatch('notifySuccess')
      dispatch('fetchAgents', {limit: '', page: '', search: '', agentId: '', masterId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_AGENT_FAILURE', e.message)
    }
  },
  
}
const getters = {
  agents: (state) => state.agents,
  masters: (state) => state.masters,
  agentsByMaster: (state) => state.agentsByMaster,
  agentDetail: (state) => state.agentDetail,
  agentBankAccounts: (state) => state.agentBankAccounts,
}
const state = {
  agents: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingAgents: true,

  masters: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingMasters: true,

  agentsByMaster: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingAgentsByMaster: true,

  agentDetail: {},
  isFetchingAgent: false,

  isCreatingAgent: false,

  isUpdatingAgent: false,

  isLoggingInAsAgent: false,

  isResettingPasswordAgent: false,

  isDepositingAgentCredit: false,

  isWithdrawingAgentCredit: false,

  isDeletingAgent: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
