import axios from 'axios'

const baseURL = `${process.env.VUE_APP_NEW_API_URL}`
const userURL = `${process.env.VUE_APP_API_URL}/user`

export const isUserLoggedIn  = () => {
  return localStorage.getItem('ADMIN_TOKEN');
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export default {
  saveAccessToken(token) {
    localStorage.setItem('ADMIN_TOKEN', token)
  },
  getAccessToken() {
    return localStorage.getItem('ADMIN_TOKEN')
  },
  getMe() {
    try {
      return axios.get(`${baseURL}/me`)
    } catch (e) {
      throw e
    }
  },
  login(username, password) {
    try {
      return axios.post(`${baseURL}/auth/login`, { username, password })
    } catch (e) {
      throw e
    }
  },
  logout() {
    try {
      return axios.delete(`${userURL}/admins/logout`)
    } catch (e) {
      throw e
    }
  },
  updateProfile(data) {
    try {
      return axios.put(`${userURL}/admins/profile`, data)
    } catch (e) {
      throw e
    }
  },
  resetPassword(data) {
    try {
      return axios.patch(`${userURL}/admins/user_agents/password`, data)
    } catch (e) {
      throw e
    }
  },
  fetchBankAccounts(limit, page, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/bank-account?limit=${limit}&page=${page}&agentId=${agentId}&masterId=${masterId}`)
    } catch (e) {
      throw e
    }
  },
  fetchBankAccountById(id) {
    try {
      return axios.get(`${baseURL}/bank-account/${id}`)
    } catch (e) {
      throw e
    }
  },
  addBankAccount(data) {
    try {
      return axios.post(`${baseURL}/bank-account`, data)
    } catch (e) {
      throw e
    }
  },
  registerSCB(id, data) {
    try {
      return axios.post(`${baseURL}/bank-account/${id}/scb/register`, data)
    } catch (e) {
      throw e
    }
  },
  verifyScbOTP(id, data) {
    try {
      return axios.post(`${baseURL}/bank-account/${id}/scb/otp`, data)
    } catch (e) {
      throw e
    }
  },
  registerTrueWallet(id, data) {
    try {
      return axios.post(
        `${baseURL}/bank-account/${id}/true-wallet/register`,
        data
      )
    } catch (e) {
      throw e
    }
  },
  verifyTrueWallet(id, data) {
    try {
      return axios.post(`${baseURL}/bank-account/${id}/true-wallet/otp`, data)
    } catch (e) {
      throw e
    }
  },
  addAgentBankAccount(data) {
    try {
      return axios.post(`${userURL}/admins/profile/bank_accounts_with_id`, data)
    } catch (e) {
      throw e
    }
  },
  updateBankAccount(id, data) {
    try {
      return axios.put(`${baseURL}/bank-account/${id}`, data)
    } catch (e) {
      throw e
    }
  },
  deleteBankAccount(id) {
    try {
      return axios.delete(`${baseURL}/bank-account/${id}`)
    } catch (e) {
      throw e
    }
  },
  fetchWithdrawSettings() {
    try {
      return axios.get(`${userURL}/admins`)
    } catch (e) {
      throw e
    }
  },
  withdrawSettings(data) {
    try {
      return axios.put(`${userURL}/admins`, data)
    } catch (e) {
      throw e
    }
  },
  uploadFile(file) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(
        `${baseURL}/admins/agents/upload/image`,
        formData,
        options
      )
    } catch (e) {
      throw e
    }
  },
  uploadFileWithAgentId(file, agent_id) {
    try {
      const ext = file.name.split('.')[file.name.split('.').length - 1]
      const formData = new FormData()
      formData.append(
        'file',
        file,
        file.name.split('.')[0] +
          new Date().getTime().toString() +
          'agent' +
          agent_id +
          '.' +
          ext
      )
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios.post(
        `${userURL}/admins/agents/upload/image-non-unique`,
        formData,
        options
      )
    } catch (e) {
      throw e
    }
  },
  fetchUserAccess(limit, page, search, masterId, agentId) {
    try {
      return axios.get(
        `${baseURL}/agent-login-log?limit=${limit}&page=${page}&search=${search}&masterId=${masterId}&agentId=${agentId}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchDashboard(date, masterId, agentId) {
    try {
      return axios.get(
        `${baseURL}/dashboard?date=${date}&masterId=${masterId}&agentId=${agentId}`
      )
    } catch (e) {
      throw e
    }
  },
  fetchSummary(search, masterId, agentId, from, to) {
    try {
      return axios.get(`${baseURL}/summary?search=${search}&masterId=${masterId}&agentId=${agentId}&from=${from}&to=${to}`)
    } catch (e) {
      throw e
    }
  },
  verifyLogin(otp) {
    try {
      return axios.post(
        `${baseURL}/${otp}`
      )
    } catch (e) {
      throw e
    }
  },
}
