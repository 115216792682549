import Vue from 'vue'
import { mapGetters } from 'vuex'
import { banks } from './constants'

Vue.mixin({
  data() {
    return {
      bankList: banks,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters(['adminPermissions']),
    $windowWidth() {
      return this.windowWidth
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    $displayStatusColor(status) {
      const val = status ? status.toLowerCase() : ''
      if (val === 'active') {
        return 'success'
      }
      if (val === 'blocked') {
        return 'danger'
      }
      if (val === 'inactive') {
        return 'dark'
      }
      return ''
    },
    $displayBankName(bankCode) {
      const found = this.bankList.find((item) => item.bank_code === bankCode)
      return found ? found.bank_name_th : bankCode
    },
    $allowPermission(permission) {
      switch (permission) {
        case 'credit':
        case 'settings':
          return true;
        default:
          return !!this.adminPermissions.find((per) => per.key === permission)
      }
    },
    $addPageToLocation(page) {
      history.pushState(
        {},
        null,
        `${this.$route.path}?page=${page}`
      )
    },
    $navigateQueryToLocation(route, { query }) {
      const { key, value } = query;
      this.$router.push(`${route}?${key}=${value}`)
    },
  },
})
