export default [
  {
    key: 'agent:manage.deposit',
    icon: 'ArrowUpCircleIcon',
    path: '/deposit',
    name: 'VIEW_TRANSACTION_DEPOSIT',
    route: 'VIEW_TRANSACTION_DEPOSIT',
    component: () => import('@/views/transactions/TransactionDeposit.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: true
    },
  },
  {
    key: 'agent:manage.withdraw',
    icon: 'ArrowDownCircleIcon',
    path: '/withdraw',
    name: 'VIEW_TRANSACTION_WITHDRAW',
    route: 'VIEW_TRANSACTION_WITHDRAW',
    component: () => import('@/views/transactions/TransactionWithdraw'),
    meta: { 
      authRequired: true,
      isAppMenu: true
    },
  },
]