import axios from 'axios'
import { v4 as uuid } from 'uuid';

const baseURL = `${process.env.VUE_APP_NEW_API_URL}/deposit`

export default {
  fetchDepositDone(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/done?limit=${limit}&page=${page}&search=${search}&masterId=${masterId}&agentId=${agentId}`)
    } catch (e) {
      throw e
    }
  },
  fetchDepositUnmatched(limit, page, search, masterId, agentId) {
    try {
      return axios.get(`${baseURL}/unmatched?limit=${limit}&page=${page}&search=${search}&masterId=${masterId}&agentId=${agentId}`)
    } catch (e) {
      throw e
    }
  },
  fetchDepositUnmatchedById(id) {
    try {
      return axios.get(`${baseURL}/${id}/unmatched`)
    } catch (e) {
      throw e
    }
  },
  addDepositUnmatched(id, data) {
    try {
      return axios.post(`${baseURL}/${id}/unmatched`, data)
    } catch (e) {
      throw e
    }
  },
  deleteDepositUnmatched(id) {
    try {
      return axios.delete(`${baseURL}/${id}/unmatched`)
    } catch (e) {
      throw e
    }
  },
  addDepositManual(agentId, data) {
    try {
      return axios.post(`${baseURL}/manual?agentId=${agentId}`, data)
    } catch (e) {
      throw e
    }
  },
  toggleDepositStatus(id, remark) {
    try {
      return axios.post(`${baseURL}/${id}/toggle`, { remark, trackingId: uuid() })
    } catch (e) {
      throw e
    }
  },
  fetchBankAccounts() {
    try {
      return axios.get(`${baseURL}/bank-accounts`)
    } catch (e) {
      throw e
    }
  },
}
