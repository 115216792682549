import axios from 'axios'
const baseURL = `${process.env.VUE_APP_NEW_API_URL}`

export default {
  async fetchLineNotifications(limit, page, search, masterId, agentId) {
    try {
      return axios.get(
        `${baseURL}/line-notification?limit=${limit}&page=${page}&search=${search}&agentId=${agentId}&masterId=${masterId}`
      )
    } catch (e) {
      throw e
    }
  },
  async createLineNotification(data) {
    try {
      return axios.post(`${baseURL}/line-notification`, data)
    } catch (e) {
      throw e
    }
  },
  async deleteLineNotification(id) {
    try {
      return axios.delete(`${baseURL}/line-notification/${id}`)
    } catch (e) {
      throw e
    }
  },
}
