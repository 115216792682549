import DepositService from '../../services/deposit'

const mutations = {
  FETCH_DEPOSIT_DONE_REQUEST(state) {
    state.isFetchingDepositDone = true
  },
  FETCH_DEPOSIT_DONE_SUCCESS(state, payload) {
    state.isFetchingDepositDone = false
    state.depositDone = payload
  },
  FETCH_DEPOSIT_DONE_FAILURE(state) {
    state.isFetchingDepositDone = false
  },
  FETCH_DEPOSIT_UNMATCHED_REQUEST(state) {
    state.isFetchingDepositUnmatched = true
  },
  FETCH_DEPOSIT_UNMATCHED_SUCCESS(state, payload) {
    state.isFetchingDepositUnmatched = false
    state.depositUnmatched = payload
  },
  FETCH_DEPOSIT_UNMATCHED_FAILURE(state) {
    state.isFetchingDepositUnmatched = false
  },
  FETCH_DEPOSIT_UNMATCHED_BY_ID_REQUEST(state) {
    state.isFetchingDepositUnmatchedById = true
  },
  FETCH_DEPOSIT_UNMATCHED_BY_ID_SUCCESS(state, payload) {
    state.isFetchingDepositUnmatchedById = false
    state.depositUnmatchedDetail = payload
  },
  FETCH_DEPOSIT_UNMATCHED_BY_ID_FAILURE(state) {
    state.isFetchingDepositUnmatchedById = false
  },
  ADD_DEPOSIT_UNMATCHED_REQUEST(state) {
    state.isAddingDepositUnmatched = true
    state.isAddingDepositUnmatchedSuccess = false
  },
  ADD_DEPOSIT_UNMATCHED_SUCCESS(state) {
    state.isAddingDepositUnmatched = false
    state.isAddingDepositUnmatchedSuccess = true
  },
  ADD_DEPOSIT_UNMATCHED_FAILURE(state) {
    state.isAddingDepositUnmatched = false
  },
  DELETE_DEPOSIT_UNMATCHED_REQUEST(state) {
    state.isDeletingDepositUnmatched = true
  },
  DELETE_DEPOSIT_UNMATCHED_SUCCESS(state) {
    state.isDeletingDepositUnmatched = false
  },
  DELETE_DEPOSIT_UNMATCHED_FAILURE(state) {
    state.isDeletingDepositUnmatched = false
  },
  ADD_DEPOSIT_MANUAL_REQUEST(state) {
    state.isAddingDepositManual = true
  },
  ADD_DEPOSIT_MANUAL_SUCCESS(state) {
    state.isAddingDepositManual = false
  },
  ADD_DEPOSIT_MANUAL_FAILURE(state) {
    state.isAddingDepositManual = false
  },
  TOGGLE_DEPOSIT_REQUEST(state) {
    state.isToggleDeposit = true
  },
  TOGGLE_DEPOSIT_SUCCESS(state) {
    state.isToggleDeposit = false
  },
  TOGGLE_DEPOSIT_FAILURE(state) {
    state.isToggleDeposit = false
  },
  FETCH_BANK_ACCOUNTS_REQUEST(state) {
    state.isFetchingBankAccounts = true
  },
  FETCH_BANK_ACCOUNTS_SUCCESS(state, payload) {
    state.isFetchingBankAccounts = false
    state.depositBankAccounts = payload
  },
  FETCH_BANK_ACCOUNTS_FAILURE(state) {
    state.isFetchingBankAccounts = false
  },
}
const actions = {
  async fetchDepositDone({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_DEPOSIT_DONE_REQUEST')
      const response = await DepositService.fetchDepositDone(limit, page, search, masterId, agentId)
      commit('FETCH_DEPOSIT_DONE_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_DEPOSIT_DONE_FAILURE', e.message)
    }
  },
  async fetchDepositUnmatched({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_DEPOSIT_UNMATCHED_REQUEST')
      const response = await DepositService.fetchDepositUnmatched(limit, page, search, masterId, agentId)
      commit('FETCH_DEPOSIT_UNMATCHED_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_DEPOSIT_UNMATCHED_FAILURE', e.message)
    }
  },
  async fetchDepositUnmatchedById({ commit, dispatch }, id) {
    try {
      commit('FETCH_DEPOSIT_UNMATCHED_BY_ID_REQUEST')
      const response = await DepositService.fetchDepositUnmatchedById(id)
      commit('FETCH_DEPOSIT_UNMATCHED_BY_ID_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_DEPOSIT_UNMATCHED_BY_ID_FAILURE', e.message)
    }
  },
  async addDepositUnmatched({ commit, dispatch }, {id, data}) {
    try {
      commit('ADD_DEPOSIT_UNMATCHED_REQUEST')
      await DepositService.addDepositUnmatched(id, data)
      commit('ADD_DEPOSIT_UNMATCHED_SUCCESS')
      dispatch('fetchDepositUnmatched', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('fetchDepositDone', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_DEPOSIT_UNMATCHED_FAILURE', e.message)
    }
  },
  async deleteDepositUnmatched({ commit, dispatch }, id) {
    try {
      commit('DELETE_DEPOSIT_UNMATCHED_REQUEST')
      await DepositService.deleteDepositUnmatched(id)
      commit('DELETE_DEPOSIT_UNMATCHED_SUCCESS')
      dispatch('fetchDepositUnmatched', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('fetchDepositDone', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_DEPOSIT_UNMATCHED_FAILURE', e.message)
    }
  },
  async addDepositManual({ commit, dispatch }, {agentId, data}) {
    try {
      commit('ADD_DEPOSIT_MANUAL_REQUEST')
      const response = await DepositService.addDepositManual(agentId, data)
      commit('ADD_DEPOSIT_MANUAL_SUCCESS', response)
      dispatch('fetchDepositDone', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_DEPOSIT_MANUAL_FAILURE', e.message)
    }
  },
  async toggleDepositStatus({ commit, dispatch }, { id, remark, search }) {
    try {
      commit('TOGGLE_DEPOSIT_REQUEST')
      const response = await DepositService.toggleDepositStatus(id, remark)
      commit('TOGGLE_DEPOSIT_SUCCESS', response)
      dispatch('fetchDepositDone', {limit: '', page: '', search, masterId: '', agentId: ''})
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('TOGGLE_DEPOSIT_FAILURE', e.message)
    }
  },
  async fetchDepositBankAccounts({ commit, dispatch }) {
    try {
      commit('FETCH_BANK_ACCOUNTS_REQUEST')
      const response = await DepositService.fetchBankAccounts()
      commit('FETCH_BANK_ACCOUNTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_BANK_ACCOUNTS_FAILURE', e.message)
    }
  },
}
const getters = {
  depositDone: (state) => state.depositDone,
  depositManual: (state) => state.depositManual,
  depositUnmatched: (state) => state.depositUnmatched,
  depositUnmatchedDetail: (state) => state.depositUnmatchedDetail,
  depositBankAccounts: (state) => state.depositBankAccounts,
}
const state = {
  depositDone: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingDepositDone: false,

  depositUnmatched: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingDepositUnmatched: false,
  depositUnmatchedDetail: { transaction: {}, users: []},
  isFetchingDepositUnmatchedById: false,
  isAddingDepositUnmatched: false,
  isAddingDepositUnmatchedSuccess: false,
  isDeletingDepositUnmatched: false,

  isAddingDepositManual: false,
  isToggleDeposit: false,

  depositBankAccounts: [],
  isFetchingBankAccounts: false,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
