import SettingService from '../../services/setting'

const mutations = {
  GET_NOTIFICATION_TOKEN_REQUEST(state) {
    state.isFetchingNotificationToken = true
  },
  GET_NOTIFICATION_TOKEN_SUCCESS(state, payload) {
    state.isFetchingNotificationToken = false
    state.notificationToken = payload
  },
  GET_NOTIFICATION_TOKEN_FAILURE(state) {
    state.isFetchingNotificationToken = false
  },

  SET_NOTIFICATION_TOKEN_REQUEST(state) {
    state.isUpdatingNotificationToken = true
  },
  SET_NOTIFICATION_TOKEN_SUCCESS(state) {
    state.isUpdatingNotificationToken = false
  },
  SET_NOTIFICATION_TOKEN_FAILURE(state) {
    state.isUpdatingNotificationToken = false
  },
  SET_ANNOUNCE_REQUEST(state) {
    state.isSettingAnnounce = true
  },
  SET_ANNOUNCE_SUCCESS(state) {
    state.isSettingAnnounce = false
  },
  SET_ANNOUNCE_FAILURE(state) {
    state.isSettingAnnounce = false
  },
}
const actions = {
  async fetchNotificationToken({ commit, dispatch }) {
    try {
      commit('GET_NOTIFICATION_TOKEN_REQUEST')
      const response = await SettingService.fetchNotificationToken()
      commit('GET_NOTIFICATION_TOKEN_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('GET_NOTIFICATION_TOKEN_FAILURE', e.message)
    }
  },

  async setNotificationToken({ commit, dispatch }, { line_deposit_token, line_withdraw_token}) {
    try {
      commit('SET_NOTIFICATION_TOKEN_REQUEST')
      const payload = {
        line_deposit_token: line_deposit_token,
        line_withdraw_token: line_withdraw_token,
      }
      await SettingService.setNotificationToken(payload)
      commit('SET_NOTIFICATION_TOKEN_SUCCESS')
    } catch (e) {
      dispatch('notifyError', e)
      commit('SET_NOTIFICATION_TOKEN_FAILURE', e.message)
    }
  },
  async setAnnounce({ commit, dispatch }, data) {
    try {
      commit('SET_ANNOUNCE_REQUEST')
      await SettingService.setAnnounce(data)
      commit('SET_ANNOUNCE_SUCCESS')
      dispatch('notifySuccess', 'sent')
    } catch (e) {
      dispatch('notifyError', e)
      commit('SET_ANNOUNCE_FAILURE', e.message)
    }
  },
  
}
const getters = {
  notificationToken: (state) => state.notificationToken,
}
const state = {
  notificationToken: {},
  isFetchingNotificationToken: false,
  isUpdatingNotificationToken: false,
  isSettingAnnounce: false,
}
export default {
  state,
  mutations,
  actions,
  getters,
}
