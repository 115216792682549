import WithdrawService from '../../services/withdraw'

const mutations = {
  FETCH_WITHDRAWS_REQUEST(state) {
    state.isFetchingWithdraws = true
  },
  FETCH_WITHDRAWS_SUCCESS(state, payload) {
    state.isFetchingWithdraws = false
    state.withdraws = payload
  },
  FETCH_WITHDRAWS_FAILURE(state) {
    state.isFetchingWithdraws = false
  },
  FETCH_WITHDRAW_DONE_REQUEST(state) {
    state.isFetchingWithdrawDone = true
  },
  FETCH_WITHDRAW_DONE_SUCCESS(state, payload) {
    state.isFetchingWithdrawDone = false
    state.withdrawDone = payload
  },
  FETCH_WITHDRAW_DONE_FAILURE(state) {
    state.isFetchingWithdrawDone = false
  },
  FETCH_WITHDRAW_HIDE_REQUEST(state) {
    state.isFetchingWithdrawHide = true
  },
  FETCH_WITHDRAW_HIDE_SUCCESS(state, payload) {
    state.isFetchingWithdrawHide = false
    state.withdrawHide = payload
  },
  FETCH_WITHDRAW_HIDE_FAILURE(state) {
    state.isFetchingWithdrawHide = false
  },
  REJECT_WITHDRAW_REQUEST(state) {
    state.isRejectingWithdraw = true
  },
  REJECT_WITHDRAW_SUCCESS(state) {
    state.isRejectingWithdraw = false
  },
  REJECT_WITHDRAW_FAILURE(state) {
    state.isRejectingWithdraw = false
  },
  APPROVE_WITHDRAW_REQUEST(state) {
    state.isApprovingWithdraw = true
  },
  APPROVE_WITHDRAW_SUCCESS(state) {
    state.isApprovingWithdraw = false
  },
  APPROVE_WITHDRAW_FAILURE(state) {
    state.isApprovingWithdraw = false
  },
  REQUEST_WITHDRAW_OTP_REQUEST(state) {
    state.isRequestingWithdrawOtp = true
    state.isRequestingWithdrawOtpSuccess = false
    state.withdrawOtpRef = ''
  },
  REQUEST_WITHDRAW_OTP_SUCCESS(state, payload) {
    state.isRequestingWithdrawOtp = false
    state.isRequestingWithdrawOtpSuccess = true
    state.withdrawOtpRef = payload
  },
  REQUEST_WITHDRAW_OTP_FAILURE(state) {
    state.isRequestingWithdrawOtp = false
  },
  HIDE_WITHDRAW_REQUEST(state) {
    state.isHidingWithdraw = true
  },
  HIDE_WITHDRAW_SUCCESS(state) {
    state.isHidingWithdraw = false
  },
  HIDE_WITHDRAW_FAILURE(state) {
    state.isHidingWithdraw = false
  },
  FETCH_WITHDRAW_BANK_ACCOUNTS_REQUEST(state) {
    state.isFetchingWithdrawBankAccounts = true
  },
  FETCH_WITHDRAW_BANK_ACCOUNTS_SUCCESS(state, payload) {
    state.isFetchingWithdrawBankAccounts = false
    state.withdrawBankAccounts = payload
  },
  FETCH_WITHDRAW_BANK_ACCOUNTS_FAILURE(state) {
    state.isFetchingWithdrawBankAccounts = false
  },
}
const actions = {
  async fetchWithdraws({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_WITHDRAWS_REQUEST')
      const response = await WithdrawService.fetchWithdraws(limit, page, search, masterId, agentId)
      commit('FETCH_WITHDRAWS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAWS_FAILURE', e.message)
    }
  },
  async fetchWithdrawDone({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_WITHDRAW_DONE_REQUEST')
      const response = await WithdrawService.fetchWithdrawDone(limit, page, search, masterId, agentId)
      commit('FETCH_WITHDRAW_DONE_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAW_DONE_FAILURE', e.message)
    }
  },
  async fetchWithdrawHide({ commit, dispatch }, {limit = '', page = '', search = '', masterId = '', agentId = ''}) {
    try {
      commit('FETCH_WITHDRAW_HIDE_REQUEST')
      const response = await WithdrawService.fetchWithdrawHide(limit, page, search, masterId, agentId)
      commit('FETCH_WITHDRAW_HIDE_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAW_HIDE_FAILURE', e.message)
    }
  },
  async rejectWithdraw({ commit, dispatch }, {id, data}) {
    try {
      commit('REJECT_WITHDRAW_REQUEST')
      await WithdrawService.rejectWithdraw(id, data)
      commit('REJECT_WITHDRAW_SUCCESS')
      dispatch('fetchWithdraws', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('fetchWithdrawDone', {limit: '', page: '', search: '', masterId: '', agentId: ''})
    } catch (e) {
      dispatch('notifyError', e)
      commit('REJECT_WITHDRAW_FAILURE', e.message)
    }
  },
  async approveWithdraw({ commit, dispatch }, {id, data}) {
    try {
      commit('APPROVE_WITHDRAW_REQUEST')
      await WithdrawService.approveWithdraw(id, data)
      commit('APPROVE_WITHDRAW_SUCCESS')
      dispatch('fetchWithdraws', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('fetchWithdrawDone', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('fetchWithdrawHide', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('notifySuccess', 'sent')
    } catch (e) {
      dispatch('notifyError', e)
      commit('APPROVE_WITHDRAW_FAILURE', e.message)
    }
  },
  async requestWithdrawOtp({ commit, dispatch }, {id, agentBankAccountId}) {
    try {
      commit('REQUEST_WITHDRAW_OTP_REQUEST')
      const { ref } = await WithdrawService.requestWithdrawOtp(id, agentBankAccountId)
      commit('REQUEST_WITHDRAW_OTP_SUCCESS', ref)
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('REQUEST_WITHDRAW_OTP_FAILURE', e.message)
    }
  },
  async hideWithdraw({ commit, dispatch }, id) {
    try {
      commit('HIDE_WITHDRAW_REQUEST')
      await WithdrawService.hideWithdraw(id)
      commit('HIDE_WITHDRAW_SUCCESS')
      dispatch('fetchWithdraws', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('fetchWithdrawHide', {limit: '', page: '', search: '', masterId: '', agentId: ''})
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('HIDE_WITHDRAW_FAILURE', e.message)
    }
  },
  async fetchWithdrawBankAccounts({ commit, dispatch }) {
    try {
      commit('FETCH_WITHDRAW_BANK_ACCOUNTS_REQUEST')
      const response = await WithdrawService.fetchBankAccounts()
      commit('FETCH_WITHDRAW_BANK_ACCOUNTS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_WITHDRAW_BANK_ACCOUNTS_FAILURE', e.message)
    }
  },
}
const getters = {
  withdraws: (state) => state.withdraws,
  withdrawDone: (state) => state.withdrawDone,
  withdrawHide: (state) => state.withdrawHide,
  withdrawBankAccounts: (state) => state.withdrawBankAccounts,
}
const state = {
  withdraws: {
    bankAccounts: [],
    transactions: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  },
  
  isFetchingWithdraws: false,

  withdrawDone: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingWithdrawDone: false,

  withdrawHide: {
    bankAccounts: [],
    transactions: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  },
  isFetchingWithdrawHide: false,

  isRejectingWithdraw: false,
  isApprovingWithdraw: false,
  isHidingWithdraw: false,

  withdrawBankAccounts: [],
  isFetchingWithdrawBankAccounts: false,

  isRequestingWithdrawOtp: false,
  isRequestingWithdrawOtpSuccess: false,
  withdrawOtpRef: '',
}

export default {
  state,
  mutations,
  actions,
  getters,
}
