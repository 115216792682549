import PlayerService from '../../services/player'
import router from '../../router';

const mutations = {
  CREATE_PLAYER_REQUEST(state) {
    state.isCreatingPlayer = true
  },
  CREATE_PLAYER_SUCCESS(state) {
    state.isCreatingPlayer = false
  },
  CREATE_PLAYER_FAILURE(state) {
    state.isCreatingPlayer = false
  },
  FETCH_PLAYERS_REQUEST(state) {
    state.isFetchingPlayers = true
  },
  FETCH_PLAYERS_SUCCESS(state, payload) {
    state.isFetchingPlayers = false
    state.players = payload
  },
  FETCH_PLAYERS_FAILURE(state) {
    state.isFetchingPlayers = false
  },
  FETCH_PLAYER_REQUEST(state) {
    state.isFetchingPlayer = true
  },
  FETCH_PLAYER_SUCCESS(state, payload) {
    state.isFetchingPlayer = false
    state.playerDetail = { ...state.playerDetail, ...payload}
  },
  FETCH_PLAYER_FAILURE(state) {
    state.isFetchingPlayer = false
  },
  UPDATE_PLAYER_REQUEST(state) {
    state.isUpdatingPlayer = true
    state.isUpdatingPlayerSuccess = false
  },
  UPDATE_PLAYER_SUCCESS(state) {
    state.isUpdatingPlayer = false
    state.isUpdatingPlayerSuccess = true
  },
  UPDATE_PLAYER_FAILURE(state) {
    state.isUpdatingPlayer = false
  },
  BAN_PLAYER_REQUEST(state) {
    state.isBanningPlayer = true
  },
  BAN_PLAYER_SUCCESS(state) {
    state.isBanningPlayer = false
  },
  BAN_PLAYER_FAILURE(state) {
    state.isBanningPlayer = false
  },
  DELETE_PLAYER_REQUEST(state) {
    state.isDeletingPlayer = true
  },
  DELETE_PLAYER_SUCCESS(state) {
    state.isDeletingPlayer = false
  },
  DELETE_PLAYER_FAILURE(state) {
    state.isDeletingPlayer = false
  },
  DEPOSIT_PLAYER_CREDIT_REQUEST(state) {
    state.isDepositingPlayerCredit = true
  },
  DEPOSIT_PLAYER_CREDIT_SUCCESS(state) {
    state.isDepositingPlayerCredit = false
  },
  DEPOSIT_PLAYER_CREDIT_FAILURE(state) {
    state.isDepositingPlayerCredit = false
  },
  WITHDRAW_PLAYER_CREDIT_REQUEST(state) {
    state.isWithdrawingPlayerCredit = true
  },
  WITHDRAW_PLAYER_CREDIT_SUCCESS(state) {
    state.isWithdrawingPlayerCredit = false
  },
  WITHDRAW_PLAYER_CREDIT_FAILURE(state) {
    state.isWithdrawingPlayerCredit = false
  },
  ADD_FREE_CREDIT_PLAYER_REQUEST(state) {
    state.isAddingPlayerFreeCredit = true
  },
  ADD_FREE_CREDIT_PLAYER_SUCCESS(state) {
    state.isAddingPlayerFreeCredit = false
  },
  ADD_FREE_CREDIT_PLAYER_FAILURE(state) {
    state.isAddingPlayerFreeCredit = false
  },
  FETCH_PLAYER_AF_REQUEST(state) {
    state.isFetchingPlayerAf = true
  },
  FETCH_PLAYER_AF_SUCCESS(state, payload) {
    state.isFetchingPlayerAf = false
    state.playerAfLogs = payload
  },
  FETCH_PLAYER_AF_FAILURE(state) {
    state.isFetchingPlayerAf = false
  },
  FETCH_PLAYER_CASHBACK_REQUEST(state) {
    state.isFetchingPlayerCashback = true
  },
  FETCH_PLAYER_CASHBACK_SUCCESS(state, payload) {
    state.isFetchingPlayerCashback = false
    state.playerCashbackLogs = payload
  },
  FETCH_PLAYER_CASHBACK_FAILURE(state) {
    state.isFetchingPlayerCashback = false
  },
  FETCH_PLAYER_PROMOTION_REQUEST(state) {
    state.isFetchingPlayerPromotion = true
  },
  FETCH_PLAYER_PROMOTION_SUCCESS(state, payload) {
    state.isFetchingPlayerPromotion = false
    state.playerPromotionLogs = payload
  },
  FETCH_PLAYER_PROMOTION_FAILURE(state) {
    state.isFetchingPlayerPromotion = false
  },
}
const actions = {
  async createPlayer({ commit, dispatch }, data) {
    try {
      commit('CREATE_PLAYER_REQUEST')
      const response = await PlayerService.createPlayer(data)
      commit('CREATE_PLAYER_SUCCESS', response)
      dispatch('fetchPlayers', {limit: '', page: '', search: '', agentId: '', masterId: ''})
      dispatch('notifySuccess', 'created')
      router.push('/players/list')
    } catch (e) {
      dispatch('notifyError', e)
      commit('CREATE_PLAYER_FAILURE', e.message)
    }
  },
  async fetchPlayers(
    { commit, dispatch },
    { limit = '', page = '', search = '', agentId = '', masterId = '' }
  ) {
    try {
      const response = await PlayerService.getPlayers(limit, page, search, masterId, agentId)
      commit('FETCH_PLAYERS_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYERS_FAILURE', e.message)
    }
  },
  async fetchPlayer({ commit, dispatch }, id) {
    try {
      commit('FETCH_PLAYER_REQUEST')
      const response = await PlayerService.fetchPlayerById(id)
      commit('FETCH_PLAYER_SUCCESS', response)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYER_FAILURE', e.message)
    }
  },
  async updatePlayer({ commit, dispatch }, { playerId, data, search }) {
    try {
      commit('UPDATE_PLAYER_REQUEST')
      await PlayerService.updatePlayer(playerId, data)
      dispatch('fetchPlayers', { search })
      commit('UPDATE_PLAYER_SUCCESS')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('UPDATE_PLAYER_FAILURE', e.message)
    }
  },
  async banPlayer({ commit, dispatch }, userId) {
    try {
      commit('BAN_PLAYER_REQUEST')
      await PlayerService.banPlayer(userId)
      commit('BAN_PLAYER_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('BAN_PLAYER_FAILURE', e.message)
    }
  },
  async deletePlayer({ commit, dispatch }, userId) {
    try {
      commit('DELETE_PLAYER_REQUEST')
      await PlayerService.deletePlayer(userId)
      commit('DELETE_PLAYER_SUCCESS')
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DELETE_PLAYER_FAILURE', e.message)
    }
  },
  async resetPasswordPlayer({ commit, dispatch }, data) {
    try {
      commit('RESET_PASSWORD_PLAYER_REQUEST')
      await PlayerService.resetPassword(data.id, data)
      commit('RESET_PASSWORD_PLAYER_SUCCESS')
      dispatch('notifySuccess', 'updated')
    } catch (e) {
      dispatch('notifyError', e)
      commit('RESET_PASSWORD_PLAYER_FAILURE', e.message)
    }
  },
  async depositPlayerCredit({ commit, dispatch }, { playerId, data, search }) {
    try {
      commit('DEPOSIT_PLAYER_CREDIT_REQUEST')
      await PlayerService.depositPlayerCredit(playerId, data)
      commit('DEPOSIT_PLAYER_CREDIT_SUCCESS')
      dispatch('fetchPlayers', { search })
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('DEPOSIT_PLAYER_CREDIT_FAILURE', e.message)
    }
  },
  async withdrawPlayerCredit({ commit, dispatch }, { playerId, data, search }) {
    try {
      commit('WITHDRAW_PLAYER_CREDIT_REQUEST')
      await PlayerService.withdrawPlayerCredit(playerId, data)
      commit('WITHDRAW_PLAYER_CREDIT_SUCCESS')
      dispatch('fetchPlayers', { search })
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('WITHDRAW_PLAYER_CREDIT_FAILURE', e.message)
    }
  },
  async addFreeCreditPlayer({ commit, dispatch }, { playerId, search }) {
    try {
      commit('ADD_FREE_CREDIT_PLAYER_REQUEST')
      await PlayerService.addFreeCreditPlayer(playerId)
      commit('ADD_FREE_CREDIT_PLAYER_SUCCESS')
      dispatch('fetchPlayers', { search })
      dispatch('notifySuccess')
    } catch (e) {
      dispatch('notifyError', e)
      commit('ADD_FREE_CREDIT_PLAYER_FAILURE', e.message)
    }
  },
  async fetchPlayerAfLogs({ commit, dispatch }, playerId) {
    try {
      commit('FETCH_PLAYER_AF_REQUEST')
      const result = await PlayerService.fetchPlayerAf(playerId)
      commit('FETCH_PLAYER_AF_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYER_AF_FAILURE', e.message)
    }
  },
  async fetchPlayerCashbackLogs({ commit, dispatch }, playerId) {
    try {
      commit('FETCH_PLAYER_CASHBACK_REQUEST')
      const result = await PlayerService.fetchPlayerCashback(playerId)
      commit('FETCH_PLAYER_CASHBACK_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYER_CASHBACK_FAILURE', e.message)
    }
  },
  async fetchPlayerPromotionLogs({ commit, dispatch }, playerId) {
    try {
      commit('FETCH_PLAYER_PROMOTION_REQUEST')
      const result = await PlayerService.fetchPlayerPromotion(playerId)
      commit('FETCH_PLAYER_PROMOTION_SUCCESS', result)
    } catch (e) {
      dispatch('notifyError', e)
      commit('FETCH_PLAYER_PROMOTION_FAILURE', e.message)
    }
  },
}
const getters = {
  players: (state) => state.players,
  playerDetail: (state) => state.playerDetail,
  playerAfLogs: (state) => state.playerAfLogs,
  playerCashbackLogs: (state) => state.playerCashbackLogs,
  playerPromotionLogs: (state) => state.playerPromotionLogs,
}
const state = {
  players: { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0,totalPages: 0 }},
  isFetchingPlayers: false,
  isCreatingPlayer: false,

  playerDetail: {
    affiliateByUsername: '',
    createdAt: '',
    lastLoggedInAt: '',
    bankAccount: {
      bankAccountNumber: '',
      bankCode: '',
    },
    profile: {
      username: '',
      phoneNumber: '',
      affiliateLink: '',
      codeName: '',
      firstname: '',
      lastname: '',
      note: '',
    },
    summary: {
      affiliateCredit: 0,
      affiliateMemberCount: 0,
      affiliateTotalCredit: 0,
      cashbackCredit: 0,
      cashbackTotalCredit: 0,
      credit: 0,
      holdCredit: 0,
      totalBonus: 0,
      totalDeposit: 0,
      totalWithdraw: 0,
    },
  },
  isFetchingPlayer: false,

  isUpdatingPlayer: false,
  isUpdatingPlayerSuccess: false,
  
  isBanningPlayer: false,

  isDeletingPlayer: false,

  isDepositingPlayerCredit: false,
  isWithdrawingPlayerCredit: false,
  isAddingPlayerFreeCredit: false,

  isFetchingPlayerAf: false,
  playerAfLogs: [],

  isFetchingPlayerCashback: false,
  playerCashbackLogs: [],

  isFetchingPlayerPromotion: false,
  playerPromotionLogs: [],
}
export default {
  state,
  mutations,
  actions,
  getters,
}
