import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import AgentSelectInput from '@/views/commons/AgentSelectInput.vue'
import MasterSelectInput from '@/views/commons/MasterSelectInput.vue'
import BankIcon from '@/views/commons/BankIcon.vue'
import DayPicker from '@/views/commons/DayPicker.vue'
import DateTimePicker from '@/views/commons/DateTimePicker.vue'
import PaginationInput from '@/views/commons/PaginationInput.vue'
import PageLimitSelect from '@/views/commons/PageLimitSelect.vue'
// Register it globally
// libs
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
// custom components
Vue.component('BankIcon', BankIcon)
Vue.component('DayPicker', DayPicker)
Vue.component('DateTimePicker', DateTimePicker)
Vue.component('PaginationInput', PaginationInput)
Vue.component('PageLimitSelect', PageLimitSelect)
Vue.component(AgentSelectInput.name, AgentSelectInput)
Vue.component(MasterSelectInput.name, MasterSelectInput)