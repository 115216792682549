export default [
  {
    key: 'master:view.agent',
    icon: 'SmileIcon',
    path: '/agents/list',
    name: 'VIEW_AGENTS',
    route: 'VIEW_AGENTS',
    component: () => import('@/views/agents/AgentList.vue'),
    meta: { 
      authRequired: true,
      isAppMenu: true 
    },
  },
  {
    path: '/agents',
    name: 'AGENTS',
    meta: { 
      authRequired: true,
      isAppMenu: false,
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'create',
        name: 'CREATE_AGENT',
        component: () => import('@/views/agents/AgentDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/agents/list', name: 'VIEW_AGENTS' },
            { href: '/agents/create', name: 'CREATE_AGENT' },
          ],
        },
      },
      {
        path: 'detail/:id',
        name: 'VIEW_AGENT_DETAIL',
        component: () => import('@/views/agents/AgentDetail.vue'),
        meta: {
          authRequired: true,
          breadCrumbs: [
            { href: '/agents/list', name: 'VIEW_AGENTS' },
            { href: '/agents/detail/:id', name: 'VIEW_AGENT_DETAIL' },
          ],
        },
      },
    ],
  },
]